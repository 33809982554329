import { Input, DatePicker } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { searchIcon } from "svgs";
import moment, { Moment } from "moment";

interface TripsHeaderProps {
  selectedDate: Moment | null;
  onDateChange: (date: Moment | null, dateString: string) => void;
  searchText: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDownloadCsv: () => void;
}

export default function TripsHeader({
  selectedDate,
  onDateChange,
  searchText,
  onSearchChange,
  onDownloadCsv,
}: TripsHeaderProps) {
  return (
    <div
      className="common-header"
      style={{
        display: "flex",
        justifyContent: "space-between", // Space between left and right groups
        alignItems: "center", // Vertically center items
        width: "100%", // Ensure the header takes full width
      }}
    >
      <div style={{ flex: 1, maxWidth: "300px"}}>
        <Input
          className="search-box filter-search --standard"
          placeholder="Search..."
          value={searchText}
          prefix={searchIcon}
          onChange={onSearchChange}
          style={{ width: "100%" , height: "35px"}}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "16px", // Space between date picker and download icon
          marginRight: "20px", // Space from the right edge
        }}
      >
        <DatePicker
          onChange={onDateChange}
          value={selectedDate}
          format="DD-MM-YYYY"
          disabledDate={(current) => current && current > moment().endOf("day")}
        />
        <PrinterOutlined
          style={{ fontSize: "20px", cursor: "pointer" }}
          onClick={onDownloadCsv}
        />
      </div>
    </div>
  );
}