import { DashboardActions, DashboardState } from "types";
import { Dispatch } from "react";

export function setSelectedDashboard(
  payload: DashboardState["selectedDashboard"]
): DashboardActions {
  return {
    type: "SET_SELECTED_DASHBOARD",
    payload,
  };
}

export function setRiderSearch(payload: DashboardState["searchRider"]): DashboardActions {
  return {
    type: "SET_RIDER_SEARCH",
    payload,
  };
}


// export function getDashboadValues() {
//   return (dispatch: Dispatch<any>) => {
//     const request = new DashboardRequest();

//     function dispatcher(resp: DashboardResponse) {
//       const values = resp
//         ?.getDashboardValues().map(( values)=> values.cloneMessage()?.toObject() as DashboadItem.AsObject
//         );
//       dispatch(setDashboardValues(values));
//     }
//     dispatch(
//       apiResponse<
//         DashboardServiceClient,
//         DashboardValueRequest,
//         DashboardValueResponse
//       >({
//         request,
//         dispatcher,
//         action: "getDashboardValues",
//         client: DashboardServiceClient,
//         triggerLoading: false,
//       })
//     );
//   };
// }

// export function setDashboardValues(
//   payload: DashboardState["dashboardValues"]
// ): DashboardActions {
//   return {
//     type: "SET_DASHBOARD_VALUE",
//     payload,
//   };
// }