import {BillingComponents, BillingLineItem, BillingsComponentsData, BillingState} from "../../types";
import {monthNames, getNumberValue, formatDate} from "../../store/actions";


export function parseBillingFieldsTable(
    bill?: BillingState["bill"]
){
    let data = {
        distance: 0,
        batterySubCharges: 0,
        swaps: 0,
        swapCharges: 0,
        perSwapCost: "-",
        perKmCost:"-",
        dueDate: "",
        subTotal: 0,
        tax: 0,
        totalBill: 0,
        monthYear: "",
        totalBikes:0,
        billingItems: [] as BillingLineItem[],
    }
    if (bill) {
        console.log("billing items : ", bill.billingItems)
        let parseJsonBillingItems:BillingComponents = JSON.parse(bill.billingItems)
        console.log("parseJson : ", parseJsonBillingItems)
        data.batterySubCharges = 0
        data.distance = 0
        data.swaps = 0
        data.perSwapCost = "-"
        data.perKmCost = "-"
        data.swapCharges = 0
        if(parseJsonBillingItems.lineItems.length > 1) {
            data.batterySubCharges = parseJsonBillingItems.lineItems[1].total
            data.perKmCost = parseJsonBillingItems.lineItems[1].rate.toString() != "0"? "PKR "+ parseFloat(parseJsonBillingItems.lineItems[1].rate.toString()).toFixed(2) : "-"
            data.distance = parseJsonBillingItems.lineItems[1].quantity
        }
        if(parseJsonBillingItems.lineItems.length > 0) {
            data.swaps = parseJsonBillingItems.lineItems[0].quantity
            data.perSwapCost = parseJsonBillingItems.lineItems[0].rate.toString() != "0"? "PKR "+parseJsonBillingItems.lineItems[0].rate.toString(): "-"
            data.swapCharges = parseJsonBillingItems.lineItems[0].total
        }
     
        data.tax =  getNumberValue(bill.components?.fieldsMap || [], "tax") || 0;
        data.subTotal = getNumberValue(bill.components?.fieldsMap || [], "subTotal") || 0;
        data.totalBill = getNumberValue(bill.components?.fieldsMap || [], "totalBill") || 0;
        data.dueDate = bill?.dueDate === ""?"--/--/----": formatDate(bill?.dueDate)
        data.monthYear = `${monthNames[bill.billingMonth-1]}, ${bill.billingYear}`
        data.totalBikes = bill.totalOperationalBikes
        data.billingItems = parseJsonBillingItems.dynamic_items

    }
    console.log("bill:",data);
    console.log("dynamic items:",data.billingItems);
    return data
}
