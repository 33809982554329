import React, { lazy, useCallback, useState } from "react";
import Kiosks from "pages/kiosks";
import { Layout as AntLayout } from "antd";
import { Redirect } from "react-router-dom";
import { ROUTES } from "utils/constants";
import {
  AppHeader,
  Modal,
  AppNav,
  LoadingScreen,
  ProtectedRoute,
  AccessNotification,
} from "components";
import Clients from "pages/clients";


const Issues = lazy(() => import("pages/issues"));
const Vehicles = lazy(() => import("pages/vehicles"));
const Notifications = lazy(() => import("pages/notifications"));
const Trips = lazy(() => import("pages/trips"));
const VehicleModels = lazy(() => import("pages/vehicle_model"));
const Users = lazy(() => import("pages/users"));
const Zones = lazy(() => import("pages/zones"));
const Dashboard = lazy(() => import("pages/dashboard"));
const Financials = lazy(() => import("pages/financials"));
const NextStepPage = lazy(() => import("pages/next-step"));
const Settings = lazy(() => import("pages/settings"));
const Batteries = lazy(() => import("pages/batteries"));
const BatteryModels = lazy(() => import("pages/battery_model"));
const AddZone = lazy(() => import("pages/add-zone"));
const EditZone = lazy(() => import("pages/edit-zone"));
const BatteryHeartbeat = lazy(() => import("pages/battery-heartbeat"));
export default function Layout() {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = useCallback(() => {
    setCollapsed((st) => !st);
  }, []);

  return (
    <AntLayout className="full-height">
      <Modal />
      <LoadingScreen customClass="--app-loading-screen" />
      <AppHeader toggleCollapse={toggleCollapse} />
      {/* <AccessNotification /> */}
      <AntLayout className="body-content">
        <AppNav collapsed={collapsed} />
        <AntLayout className="__dynamic-layout">
          <ProtectedRoute exact path={"/"}>
            <Redirect to={ROUTES.users} />
          </ProtectedRoute>
          <ProtectedRoute exact path={ROUTES.dashboard}>
            <Dashboard />
          </ProtectedRoute>
          <ProtectedRoute path={ROUTES.vehicles}>
            <Vehicles />
          </ProtectedRoute>
          <ProtectedRoute path={ROUTES.vehicle_model}>
            {/* <VehicleModels /> */}
            <Vehicles />
          </ProtectedRoute>
          <ProtectedRoute path={ROUTES.battery}>
            <Batteries />
          </ProtectedRoute>
          <ProtectedRoute path={ROUTES.battery_model}>
            <BatteryModels />
          </ProtectedRoute>
          <ProtectedRoute exact path={ROUTES.clients}>
            <Clients />
          </ProtectedRoute>
          <ProtectedRoute exact path={ROUTES.notifications}>
            <Notifications />
          </ProtectedRoute>
          <ProtectedRoute exact path={ROUTES.trips}>
            <Trips />
          </ProtectedRoute>
          <ProtectedRoute exact path={ROUTES.kiosks}>
            <Kiosks />
          </ProtectedRoute>
          <ProtectedRoute path={ROUTES.users}>
            <Users />
          </ProtectedRoute>
          <ProtectedRoute path={ROUTES.zones}>
            <Zones />
          </ProtectedRoute>
          <ProtectedRoute path={ROUTES.issues}>
            <Issues />
          </ProtectedRoute>
          <ProtectedRoute exact path={ROUTES.financials}>
            <Financials />
          </ProtectedRoute>
          <ProtectedRoute exact path={ROUTES.next_step}>
            <NextStepPage />
          </ProtectedRoute>
          <ProtectedRoute exact path={ROUTES.settings}>
            <Settings />
          </ProtectedRoute>
          <ProtectedRoute exact path={ROUTES.add_zone}>
            <AddZone />
          </ProtectedRoute>
          <ProtectedRoute exact path={ROUTES.edit_zone}>
            <EditZone />
          </ProtectedRoute>
          <ProtectedRoute exact path={ROUTES.battery_heartbeat}>
            <BatteryHeartbeat />
          </ProtectedRoute>
        </AntLayout>
      </AntLayout>
    </AntLayout>
  );
}
