import { Table } from "components/base";
import { TableProps } from "antd/lib/table";

const columns: TableProps<any>["columns"] = [
    { title: "Rider Name", dataIndex: "rider_id"},
    { title: "Efficient Driving Score", dataIndex: "driving_score"},
    { title: "Total Rides Performed", dataIndex: "rides"},
  { title: "Safety Rating", dataIndex: "safety_rating"},
  { title: "Driving Distance (KMs)", dataIndex: "distance" },
  { title: "Total Driving Time (Hrs)", dataIndex: "time"},
  { title: "Range Score", dataIndex: "range",  align: "center" },
];

const data = [
  { key: 0, rider_id: "Ahmed", driving_score: "02", rides: "12",safety_rating:"03",distance:"323",time:"12:09",range:"08" },
  { key: 1, rider_id: "Imran", driving_score: "04", rides: "10",safety_rating:"04",distance:"483",time:"16:00",range:"06" },
  { key: 2, rider_id: "Zaid", driving_score: "06", rides: "16",safety_rating:"06",distance:"723",time:"17:02",range:"07" },

];

export default function RiderStatsTable() {
  return (
    <>
      <section className="dashboard-table-header">
        <strong className="dashboard-table-title">
          Rider's Stats
        </strong>
        {/* <a className="dashboard-table-link">View All</a> */}
      </section>

      <Table
        customClasses="--rider-stats"
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ y: 85 }}
      />
    </>
  );
}
