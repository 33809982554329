import { Formik } from "formik";
import {useCallback, useState} from "react";
import PhoneInput from "react-phone-input-2";
import { Form, Button, Typography, Input, Upload, message } from "antd";
import { UploadFile } from "antd/lib/upload/interface";

import {
  userRoles,
} from "components/tab-content/users-content/__mock__";
import { dialCodePrefix, fileToByteArray } from "utils/helpers";
import { FormItem, SelectSearch } from "components";
import { USER_FORMIK } from "utils/formik-data";
import { UserValues } from "types/formik";
import { SelectType } from "types";
import { uploadIcon } from "svgs";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {RcFile} from "antd/lib/upload";

const { Title, Text } = Typography;

interface ComponentProps {
  initialValues: UserValues;
  vehiclesOptions: SelectType;
  organizationOptions: SelectType,
  setValues: (values: UserValues) => void;
  userType: any;
  onCancel?: React.MouseEventHandler<HTMLElement>;
}



export default function AddUserForm(props: ComponentProps) {

  const { initialValues, vehiclesOptions, organizationOptions, onCancel, setValues, userType } = props;
  const [rider, setRider] = useState(0);
  const [msg, setMsg] = useState("");
  const [orgshow, setOrgShow] = useState(1)

  const onSubmit = useCallback(
    (values: UserValues) => {
      setValues(values);
    },
    [setValues]
  );

  let userRolesByCurrentUser = userRoles
  if (userType == 2) {
    userRolesByCurrentUser = userRoles.filter((role => role.value != "1" && role.value != "3"))
  } else if (userType == 3 || userType == 5) {
    userRolesByCurrentUser = userRoles.filter((role => role.value != "1" && role.value != "5" && role.value != "2" && role.value != "6"))
  }

  const customRequest = ({
                           onSuccess,
                           onError,
                           file,
                         }: {
    onSuccess: (response: object, file: RcFile) => void;
    onError: (error: Error, response?: any, file?: RcFile | undefined) => void;
    file: RcFile;
  }) => {
    // Simulate an async upload process
    setTimeout(() => {
      if (file.size > 500*1024) {
        onError(new Error("File size must be less then 50Kb"))
        message.error('File size must be less then 50Kb');
        setMsg("File size must be less then 500Kb")
        return;
      } else if (!(file.type in ['image/png', 'application/pdf'])) {
        onError(new Error("File must be png or pdf"))
        message.error('You can only upload PNG and PDF files!');
        setMsg("You can only upload PNG and PDF files!")
        return;
      }
      setMsg("");
    }, 1000);
  };




  return (
    <Formik
      initialValues={initialValues}
      validationSchema={USER_FORMIK.validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        setFieldValue,
        dirty,
        isValid,
        handleSubmit,
        handleChange,
        values,
        errors,
      }) => (
        <div className="form-container">
          <Title level={5}>Add a User</Title>
          <Text className="description" type="secondary">
            Please provide the authentic profile details mentioned below to add
            a user
          </Text>
          <Form className="--add-users-form" onFinish={handleSubmit}>
            <section className="">
              <FormItem label="Phone Number" error={errors.phone_no} required>
                <PhoneInput
                  country="pk"
                  inputProps={{
                    required: true,
                    autoFocus: true,
                    name: "phone_no",
                  }}
                  inputClass="login-phone-input --user"
                  buttonClass="user-flag"
                  placeholder="12345678"
                  value={values.phone_no}
                  onChange={(val, data: { dialCode: string }) => {
                    setFieldValue("dial_code", data.dialCode);
                    setFieldValue("phone_no", val);
                  }}
                />
              </FormItem>
              <FormItem label="Email Address" error={errors.email} required>
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="user@email.com"
                />
              </FormItem>
              <FormItem label="Full Name" error={errors.full_name} required>
                <Input
                  name="full_name"
                  value={values.full_name}
                  onChange={handleChange}
                  placeholder="Name"
                />
              </FormItem>
              <FormItem
            label=  {"CNIC"} //{ dialCodePrefix(values.dial_code)=== "+971" ? "Emirates ID" : "CNIC"}
            error=  { errors.national_id} //{ dialCodePrefix(values.dial_code) === "+971" ? errors.emirate_id:  errors.national_id}
            required
          >
            <Input
              name={"national_id"} //{dialCodePrefix(values.dial_code) === "+971" ? "emirate_id": "national_id" }
              value= {values.national_id}  //{ dialCodePrefix(values.dial_code) === "+971" ? values.emirate_id : values.national_id}
              onChange={handleChange}
              placeholder={
                "XXXXX-XXXXXXX-X"
                // dialCodePrefix(values.dial_code) === "+971"
                //   ? "784-xxxx-xxxxxxx-x" // Placeholder for UAE
                //   : "XXXXX-XXXXXXX-X" // Placeholder for other countries
              }
            />
          </FormItem>
              <FormItem label="Role" error={errors.role} required>
                <SelectSearch
                  value={values.role}
                  items={userRolesByCurrentUser}
                  setFieldValue={setFieldValue}
                  fieldName="role"
                  placeholder="Select Role"
                  onChangeProp={(el) => { el == '3' ? setRider(1) : setRider(0); el == "5" ? setOrgShow(1) : setOrgShow(0)}}
                />
              </FormItem>
              { rider > 0 && <FormItem label="Assigned Vehicle" error={errors.vehicle}>
                <SelectSearch
                  isMultiple
                  value={values.vehicle || ""}
                  items={vehiclesOptions}
                  setFieldValue={setFieldValue}
                  fieldName="vehicle"
                  placeholder="Select"
                  onChangeProp={(value) => {
                    setFieldValue("vehicle", value);
                  }}
                />
              </FormItem>}
              
              {rider > 0 && <FormItem label="Driver’s License Number" error={errors.licence_no}>
                <Input
                  name="licence_no"
                  value={values.licence_no}
                  onChange={handleChange}
                  placeholder="000000"
                />
              </FormItem> }
              {rider > 0 && <FormItem
                label="CNIC/Driver’s License"
                error={errors.document_file}
                verticalAlign="flex-start"
              >
                <Upload.Dragger
                  name="document_file"
                  multiple={false}
                  customRequest={customRequest}
                  fileList={values.fileList}
                  accept={"png"}
                  onChange={({ fileList }) => {
                    // Getting total number of files
                    const totalFiles = fileList.length;

                    // If multiple files, pick last file
                    if (totalFiles > 1) {
                      setFieldValue("fileList", [fileList[totalFiles - 1]]);
                    } else {
                      setFieldValue("fileList", fileList);
                    }
                  }}
                  beforeUpload={async (file) =>  {
                    if(file.size > 10*1024 || !(file.type in ['image/png', 'application/pdf'])) {
                      setFieldValue("fileList", []);
                      values.fileList = []
                      isValid = false
                      return
                    }
                    const unit8Arr = await fileToByteArray(file);

                    // if (!(file.type in ["image/png"])) {
                    //   window.alert("file must png : ")
                    //    errors.full_name = "test"
                    //   setFieldValue("fileList", []);
                    //   return
                    // }
                    setFieldValue("document_file", unit8Arr);
                    setFieldValue("document_file_raw", file);
                  }}
                >
                  {uploadIcon}
                  <p className="ant-upload text">
                    <span>Support for PNG and PDF files, up to 512 KB</span><br />
                    Drag the document here or
                    <span className="secondary-color"> browse</span> from system
                  </p>
                </Upload.Dragger>
                {msg && <p className="ant-upload text-danger">{msg}</p> }
              </FormItem> }
              { userType == 1 || userType == 2 ? orgshow == 1 ?
              <FormItem label="Organization" error={errors.vehicle}>
                <SelectSearch
                    value={values.organization_id || ""}
                    items={organizationOptions}
                    setFieldValue={setFieldValue}
                    fieldName="organization_id"
                    placeholder="Select"
                    onChangeProp={(value) => {
                      setFieldValue("organization_id", value);
                    }}
                />
              </FormItem> : <></>
              : "" }
            </section>
            <div className="action-btn-container">
              <>
                <Button className="cancel-btn" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={(!isValid && dirty) || values.phone_no === ""}
                >
                  Save
                </Button>
              </>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}
