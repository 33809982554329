// source: client_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
goog.exportSymbol('proto.api.AddOrganizationRequest', null, global);
goog.exportSymbol('proto.api.AddOrganizationResponse', null, global);
goog.exportSymbol('proto.api.ClientAddress', null, global);
goog.exportSymbol('proto.api.ClientOwnerItem', null, global);
goog.exportSymbol('proto.api.FindByNameRequest', null, global);
goog.exportSymbol('proto.api.FindByNameResponse', null, global);
goog.exportSymbol('proto.api.FindOrganizationByIdRequest', null, global);
goog.exportSymbol('proto.api.FindOrganizationByIdResponse', null, global);
goog.exportSymbol('proto.api.ListOwnerOrgAdminRequest', null, global);
goog.exportSymbol('proto.api.ListOwnerOrgAdminResponse', null, global);
goog.exportSymbol('proto.api.ListOwnerOrgAdminResponse.Item', null, global);
goog.exportSymbol('proto.api.ListOwnerOrgManagementRequest', null, global);
goog.exportSymbol('proto.api.ListOwnerOrgManagementResponse', null, global);
goog.exportSymbol('proto.api.ListOwnerOrgManagementResponse.Item', null, global);
goog.exportSymbol('proto.api.NullableClientOrgId', null, global);
goog.exportSymbol('proto.api.NullableClientOrgId.IdCase', null, global);
goog.exportSymbol('proto.api.NullableOwnerOrgId', null, global);
goog.exportSymbol('proto.api.NullableOwnerOrgId.IdCase', null, global);
goog.exportSymbol('proto.api.OwnerOrgItem', null, global);
goog.exportSymbol('proto.api.OwnerOrgManagementMeRequest', null, global);
goog.exportSymbol('proto.api.OwnerOrgManagementMeResponse', null, global);
goog.exportSymbol('proto.api.OwnerOrgManagementMeResponse.Item', null, global);
goog.exportSymbol('proto.api.UpdateClientOwnerItem', null, global);
goog.exportSymbol('proto.api.UpdateOrganizationRequest', null, global);
goog.exportSymbol('proto.api.UpdateOrganizationResponse', null, global);
goog.exportSymbol('proto.api.UpdateOwnerOrgItem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddOrganizationRequest.displayName = 'proto.api.AddOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AddOrganizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AddOrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AddOrganizationResponse.displayName = 'proto.api.AddOrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateOrganizationRequest.displayName = 'proto.api.UpdateOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateOrganizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateOrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateOrganizationResponse.displayName = 'proto.api.UpdateOrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.FindOrganizationByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.FindOrganizationByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.FindOrganizationByIdRequest.displayName = 'proto.api.FindOrganizationByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.FindOrganizationByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.FindOrganizationByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.FindOrganizationByIdResponse.displayName = 'proto.api.FindOrganizationByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.FindByNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.FindByNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.FindByNameRequest.displayName = 'proto.api.FindByNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.FindByNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.FindByNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.FindByNameResponse.displayName = 'proto.api.FindByNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ClientAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ClientAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ClientAddress.displayName = 'proto.api.ClientAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.OwnerOrgManagementMeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.OwnerOrgManagementMeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.OwnerOrgManagementMeRequest.displayName = 'proto.api.OwnerOrgManagementMeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.OwnerOrgManagementMeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.OwnerOrgManagementMeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.OwnerOrgManagementMeResponse.displayName = 'proto.api.OwnerOrgManagementMeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.OwnerOrgManagementMeResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.OwnerOrgManagementMeResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.OwnerOrgManagementMeResponse.Item.displayName = 'proto.api.OwnerOrgManagementMeResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListOwnerOrgAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListOwnerOrgAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListOwnerOrgAdminRequest.displayName = 'proto.api.ListOwnerOrgAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListOwnerOrgAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListOwnerOrgAdminResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListOwnerOrgAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListOwnerOrgAdminResponse.displayName = 'proto.api.ListOwnerOrgAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListOwnerOrgAdminResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListOwnerOrgAdminResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListOwnerOrgAdminResponse.Item.displayName = 'proto.api.ListOwnerOrgAdminResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListOwnerOrgManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListOwnerOrgManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListOwnerOrgManagementRequest.displayName = 'proto.api.ListOwnerOrgManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListOwnerOrgManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.ListOwnerOrgManagementResponse.repeatedFields_, null);
};
goog.inherits(proto.api.ListOwnerOrgManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListOwnerOrgManagementResponse.displayName = 'proto.api.ListOwnerOrgManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ListOwnerOrgManagementResponse.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ListOwnerOrgManagementResponse.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ListOwnerOrgManagementResponse.Item.displayName = 'proto.api.ListOwnerOrgManagementResponse.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.NullableOwnerOrgId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.NullableOwnerOrgId.oneofGroups_);
};
goog.inherits(proto.api.NullableOwnerOrgId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.NullableOwnerOrgId.displayName = 'proto.api.NullableOwnerOrgId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.NullableClientOrgId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.NullableClientOrgId.oneofGroups_);
};
goog.inherits(proto.api.NullableClientOrgId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.NullableClientOrgId.displayName = 'proto.api.NullableClientOrgId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.OwnerOrgItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.OwnerOrgItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.OwnerOrgItem.displayName = 'proto.api.OwnerOrgItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateOwnerOrgItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateOwnerOrgItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateOwnerOrgItem.displayName = 'proto.api.UpdateOwnerOrgItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ClientOwnerItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ClientOwnerItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ClientOwnerItem.displayName = 'proto.api.ClientOwnerItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateClientOwnerItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateClientOwnerItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateClientOwnerItem.displayName = 'proto.api.UpdateClientOwnerItem';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerItem: (f = msg.getOwnerItem()) && proto.api.UpdateOwnerOrgItem.toObject(includeInstance, f),
    businessType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    clientItem: (f = msg.getClientItem()) && proto.api.UpdateClientOwnerItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddOrganizationRequest}
 */
proto.api.AddOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddOrganizationRequest;
  return proto.api.AddOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddOrganizationRequest}
 */
proto.api.AddOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.UpdateOwnerOrgItem;
      reader.readMessage(value,proto.api.UpdateOwnerOrgItem.deserializeBinaryFromReader);
      msg.setOwnerItem(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessType(value);
      break;
    case 3:
      var value = new proto.api.UpdateClientOwnerItem;
      reader.readMessage(value,proto.api.UpdateClientOwnerItem.deserializeBinaryFromReader);
      msg.setClientItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.UpdateOwnerOrgItem.serializeBinaryToWriter
    );
  }
  f = message.getBusinessType();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getClientItem();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.UpdateClientOwnerItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateOwnerOrgItem owner_item = 1;
 * @return {?proto.api.UpdateOwnerOrgItem}
 */
proto.api.AddOrganizationRequest.prototype.getOwnerItem = function() {
  return /** @type{?proto.api.UpdateOwnerOrgItem} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateOwnerOrgItem, 1));
};


/**
 * @param {?proto.api.UpdateOwnerOrgItem|undefined} value
 * @return {!proto.api.AddOrganizationRequest} returns this
*/
proto.api.AddOrganizationRequest.prototype.setOwnerItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddOrganizationRequest} returns this
 */
proto.api.AddOrganizationRequest.prototype.clearOwnerItem = function() {
  return this.setOwnerItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddOrganizationRequest.prototype.hasOwnerItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 business_type = 2;
 * @return {number}
 */
proto.api.AddOrganizationRequest.prototype.getBusinessType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.AddOrganizationRequest} returns this
 */
proto.api.AddOrganizationRequest.prototype.setBusinessType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional UpdateClientOwnerItem client_item = 3;
 * @return {?proto.api.UpdateClientOwnerItem}
 */
proto.api.AddOrganizationRequest.prototype.getClientItem = function() {
  return /** @type{?proto.api.UpdateClientOwnerItem} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateClientOwnerItem, 3));
};


/**
 * @param {?proto.api.UpdateClientOwnerItem|undefined} value
 * @return {!proto.api.AddOrganizationRequest} returns this
*/
proto.api.AddOrganizationRequest.prototype.setClientItem = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddOrganizationRequest} returns this
 */
proto.api.AddOrganizationRequest.prototype.clearClientItem = function() {
  return this.setClientItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddOrganizationRequest.prototype.hasClientItem = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AddOrganizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AddOrganizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AddOrganizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddOrganizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerItem: (f = msg.getOwnerItem()) && proto.api.OwnerOrgItem.toObject(includeInstance, f),
    businessType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    clientItem: (f = msg.getClientItem()) && proto.api.ClientOwnerItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AddOrganizationResponse}
 */
proto.api.AddOrganizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AddOrganizationResponse;
  return proto.api.AddOrganizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AddOrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AddOrganizationResponse}
 */
proto.api.AddOrganizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.OwnerOrgItem;
      reader.readMessage(value,proto.api.OwnerOrgItem.deserializeBinaryFromReader);
      msg.setOwnerItem(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessType(value);
      break;
    case 3:
      var value = new proto.api.ClientOwnerItem;
      reader.readMessage(value,proto.api.ClientOwnerItem.deserializeBinaryFromReader);
      msg.setClientItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AddOrganizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AddOrganizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AddOrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AddOrganizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.OwnerOrgItem.serializeBinaryToWriter
    );
  }
  f = message.getBusinessType();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getClientItem();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.ClientOwnerItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional OwnerOrgItem owner_item = 1;
 * @return {?proto.api.OwnerOrgItem}
 */
proto.api.AddOrganizationResponse.prototype.getOwnerItem = function() {
  return /** @type{?proto.api.OwnerOrgItem} */ (
    jspb.Message.getWrapperField(this, proto.api.OwnerOrgItem, 1));
};


/**
 * @param {?proto.api.OwnerOrgItem|undefined} value
 * @return {!proto.api.AddOrganizationResponse} returns this
*/
proto.api.AddOrganizationResponse.prototype.setOwnerItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddOrganizationResponse} returns this
 */
proto.api.AddOrganizationResponse.prototype.clearOwnerItem = function() {
  return this.setOwnerItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddOrganizationResponse.prototype.hasOwnerItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 business_type = 2;
 * @return {number}
 */
proto.api.AddOrganizationResponse.prototype.getBusinessType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.AddOrganizationResponse} returns this
 */
proto.api.AddOrganizationResponse.prototype.setBusinessType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ClientOwnerItem client_item = 3;
 * @return {?proto.api.ClientOwnerItem}
 */
proto.api.AddOrganizationResponse.prototype.getClientItem = function() {
  return /** @type{?proto.api.ClientOwnerItem} */ (
    jspb.Message.getWrapperField(this, proto.api.ClientOwnerItem, 3));
};


/**
 * @param {?proto.api.ClientOwnerItem|undefined} value
 * @return {!proto.api.AddOrganizationResponse} returns this
*/
proto.api.AddOrganizationResponse.prototype.setClientItem = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AddOrganizationResponse} returns this
 */
proto.api.AddOrganizationResponse.prototype.clearClientItem = function() {
  return this.setClientItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AddOrganizationResponse.prototype.hasClientItem = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerItem: (f = msg.getOwnerItem()) && proto.api.UpdateOwnerOrgItem.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateOrganizationRequest}
 */
proto.api.UpdateOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateOrganizationRequest;
  return proto.api.UpdateOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateOrganizationRequest}
 */
proto.api.UpdateOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.UpdateOwnerOrgItem;
      reader.readMessage(value,proto.api.UpdateOwnerOrgItem.deserializeBinaryFromReader);
      msg.setOwnerItem(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.UpdateOwnerOrgItem.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional UpdateOwnerOrgItem owner_item = 1;
 * @return {?proto.api.UpdateOwnerOrgItem}
 */
proto.api.UpdateOrganizationRequest.prototype.getOwnerItem = function() {
  return /** @type{?proto.api.UpdateOwnerOrgItem} */ (
    jspb.Message.getWrapperField(this, proto.api.UpdateOwnerOrgItem, 1));
};


/**
 * @param {?proto.api.UpdateOwnerOrgItem|undefined} value
 * @return {!proto.api.UpdateOrganizationRequest} returns this
*/
proto.api.UpdateOrganizationRequest.prototype.setOwnerItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateOrganizationRequest} returns this
 */
proto.api.UpdateOrganizationRequest.prototype.clearOwnerItem = function() {
  return this.setOwnerItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateOrganizationRequest.prototype.hasOwnerItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.api.UpdateOrganizationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateOrganizationRequest} returns this
 */
proto.api.UpdateOrganizationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateOrganizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateOrganizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateOrganizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateOrganizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerItem: (f = msg.getOwnerItem()) && proto.api.OwnerOrgItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateOrganizationResponse}
 */
proto.api.UpdateOrganizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateOrganizationResponse;
  return proto.api.UpdateOrganizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateOrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateOrganizationResponse}
 */
proto.api.UpdateOrganizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.OwnerOrgItem;
      reader.readMessage(value,proto.api.OwnerOrgItem.deserializeBinaryFromReader);
      msg.setOwnerItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateOrganizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateOrganizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateOrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateOrganizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.OwnerOrgItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional OwnerOrgItem owner_item = 1;
 * @return {?proto.api.OwnerOrgItem}
 */
proto.api.UpdateOrganizationResponse.prototype.getOwnerItem = function() {
  return /** @type{?proto.api.OwnerOrgItem} */ (
    jspb.Message.getWrapperField(this, proto.api.OwnerOrgItem, 1));
};


/**
 * @param {?proto.api.OwnerOrgItem|undefined} value
 * @return {!proto.api.UpdateOrganizationResponse} returns this
*/
proto.api.UpdateOrganizationResponse.prototype.setOwnerItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateOrganizationResponse} returns this
 */
proto.api.UpdateOrganizationResponse.prototype.clearOwnerItem = function() {
  return this.setOwnerItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateOrganizationResponse.prototype.hasOwnerItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.FindOrganizationByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.FindOrganizationByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.FindOrganizationByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.FindOrganizationByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.FindOrganizationByIdRequest}
 */
proto.api.FindOrganizationByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.FindOrganizationByIdRequest;
  return proto.api.FindOrganizationByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.FindOrganizationByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.FindOrganizationByIdRequest}
 */
proto.api.FindOrganizationByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.FindOrganizationByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.FindOrganizationByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.FindOrganizationByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.FindOrganizationByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.FindOrganizationByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.FindOrganizationByIdRequest} returns this
 */
proto.api.FindOrganizationByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.FindOrganizationByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.FindOrganizationByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.FindOrganizationByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.FindOrganizationByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerItem: (f = msg.getOwnerItem()) && proto.api.OwnerOrgItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.FindOrganizationByIdResponse}
 */
proto.api.FindOrganizationByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.FindOrganizationByIdResponse;
  return proto.api.FindOrganizationByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.FindOrganizationByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.FindOrganizationByIdResponse}
 */
proto.api.FindOrganizationByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.OwnerOrgItem;
      reader.readMessage(value,proto.api.OwnerOrgItem.deserializeBinaryFromReader);
      msg.setOwnerItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.FindOrganizationByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.FindOrganizationByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.FindOrganizationByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.FindOrganizationByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.OwnerOrgItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional OwnerOrgItem owner_item = 1;
 * @return {?proto.api.OwnerOrgItem}
 */
proto.api.FindOrganizationByIdResponse.prototype.getOwnerItem = function() {
  return /** @type{?proto.api.OwnerOrgItem} */ (
    jspb.Message.getWrapperField(this, proto.api.OwnerOrgItem, 1));
};


/**
 * @param {?proto.api.OwnerOrgItem|undefined} value
 * @return {!proto.api.FindOrganizationByIdResponse} returns this
*/
proto.api.FindOrganizationByIdResponse.prototype.setOwnerItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.FindOrganizationByIdResponse} returns this
 */
proto.api.FindOrganizationByIdResponse.prototype.clearOwnerItem = function() {
  return this.setOwnerItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.FindOrganizationByIdResponse.prototype.hasOwnerItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.FindByNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.FindByNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.FindByNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.FindByNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.FindByNameRequest}
 */
proto.api.FindByNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.FindByNameRequest;
  return proto.api.FindByNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.FindByNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.FindByNameRequest}
 */
proto.api.FindByNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.FindByNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.FindByNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.FindByNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.FindByNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.FindByNameRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.FindByNameRequest} returns this
 */
proto.api.FindByNameRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.FindByNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.FindByNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.FindByNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.FindByNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.FindByNameResponse}
 */
proto.api.FindByNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.FindByNameResponse;
  return proto.api.FindByNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.FindByNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.FindByNameResponse}
 */
proto.api.FindByNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.FindByNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.FindByNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.FindByNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.FindByNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.FindByNameResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.FindByNameResponse} returns this
 */
proto.api.FindByNameResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.FindByNameResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.FindByNameResponse} returns this
 */
proto.api.FindByNameResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ClientAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ClientAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ClientAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ClientAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    streetAddress1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    streetAddress2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ClientAddress}
 */
proto.api.ClientAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ClientAddress;
  return proto.api.ClientAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ClientAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ClientAddress}
 */
proto.api.ClientAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetAddress1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetAddress2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ClientAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ClientAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ClientAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ClientAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreetAddress1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStreetAddress2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string street_address_1 = 1;
 * @return {string}
 */
proto.api.ClientAddress.prototype.getStreetAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ClientAddress} returns this
 */
proto.api.ClientAddress.prototype.setStreetAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string street_address_2 = 2;
 * @return {string}
 */
proto.api.ClientAddress.prototype.getStreetAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ClientAddress} returns this
 */
proto.api.ClientAddress.prototype.setStreetAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.api.ClientAddress.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ClientAddress} returns this
 */
proto.api.ClientAddress.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.api.ClientAddress.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ClientAddress} returns this
 */
proto.api.ClientAddress.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.api.ClientAddress.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ClientAddress} returns this
 */
proto.api.ClientAddress.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.OwnerOrgManagementMeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.OwnerOrgManagementMeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.OwnerOrgManagementMeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.OwnerOrgManagementMeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.OwnerOrgManagementMeRequest}
 */
proto.api.OwnerOrgManagementMeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.OwnerOrgManagementMeRequest;
  return proto.api.OwnerOrgManagementMeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.OwnerOrgManagementMeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.OwnerOrgManagementMeRequest}
 */
proto.api.OwnerOrgManagementMeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.OwnerOrgManagementMeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.OwnerOrgManagementMeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.OwnerOrgManagementMeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.OwnerOrgManagementMeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.OwnerOrgManagementMeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.OwnerOrgManagementMeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.OwnerOrgManagementMeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.OwnerOrgManagementMeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrg: (f = msg.getOwnerOrg()) && proto.api.OwnerOrgManagementMeResponse.Item.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.OwnerOrgManagementMeResponse}
 */
proto.api.OwnerOrgManagementMeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.OwnerOrgManagementMeResponse;
  return proto.api.OwnerOrgManagementMeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.OwnerOrgManagementMeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.OwnerOrgManagementMeResponse}
 */
proto.api.OwnerOrgManagementMeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.OwnerOrgManagementMeResponse.Item;
      reader.readMessage(value,proto.api.OwnerOrgManagementMeResponse.Item.deserializeBinaryFromReader);
      msg.setOwnerOrg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.OwnerOrgManagementMeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.OwnerOrgManagementMeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.OwnerOrgManagementMeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.OwnerOrgManagementMeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrg();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.OwnerOrgManagementMeResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.OwnerOrgManagementMeResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.OwnerOrgManagementMeResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.OwnerOrgManagementMeResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.OwnerOrgManagementMeResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    basic: (f = msg.getBasic()) && proto.api.OwnerOrgItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.OwnerOrgManagementMeResponse.Item}
 */
proto.api.OwnerOrgManagementMeResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.OwnerOrgManagementMeResponse.Item;
  return proto.api.OwnerOrgManagementMeResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.OwnerOrgManagementMeResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.OwnerOrgManagementMeResponse.Item}
 */
proto.api.OwnerOrgManagementMeResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.OwnerOrgItem;
      reader.readMessage(value,proto.api.OwnerOrgItem.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.OwnerOrgManagementMeResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.OwnerOrgManagementMeResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.OwnerOrgManagementMeResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.OwnerOrgManagementMeResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.OwnerOrgItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional OwnerOrgItem basic = 1;
 * @return {?proto.api.OwnerOrgItem}
 */
proto.api.OwnerOrgManagementMeResponse.Item.prototype.getBasic = function() {
  return /** @type{?proto.api.OwnerOrgItem} */ (
    jspb.Message.getWrapperField(this, proto.api.OwnerOrgItem, 1));
};


/**
 * @param {?proto.api.OwnerOrgItem|undefined} value
 * @return {!proto.api.OwnerOrgManagementMeResponse.Item} returns this
*/
proto.api.OwnerOrgManagementMeResponse.Item.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.OwnerOrgManagementMeResponse.Item} returns this
 */
proto.api.OwnerOrgManagementMeResponse.Item.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.OwnerOrgManagementMeResponse.Item.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Item owner_org = 1;
 * @return {?proto.api.OwnerOrgManagementMeResponse.Item}
 */
proto.api.OwnerOrgManagementMeResponse.prototype.getOwnerOrg = function() {
  return /** @type{?proto.api.OwnerOrgManagementMeResponse.Item} */ (
    jspb.Message.getWrapperField(this, proto.api.OwnerOrgManagementMeResponse.Item, 1));
};


/**
 * @param {?proto.api.OwnerOrgManagementMeResponse.Item|undefined} value
 * @return {!proto.api.OwnerOrgManagementMeResponse} returns this
*/
proto.api.OwnerOrgManagementMeResponse.prototype.setOwnerOrg = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.OwnerOrgManagementMeResponse} returns this
 */
proto.api.OwnerOrgManagementMeResponse.prototype.clearOwnerOrg = function() {
  return this.setOwnerOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.OwnerOrgManagementMeResponse.prototype.hasOwnerOrg = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListOwnerOrgAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListOwnerOrgAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListOwnerOrgAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListOwnerOrgAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListOwnerOrgAdminRequest}
 */
proto.api.ListOwnerOrgAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListOwnerOrgAdminRequest;
  return proto.api.ListOwnerOrgAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListOwnerOrgAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListOwnerOrgAdminRequest}
 */
proto.api.ListOwnerOrgAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListOwnerOrgAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListOwnerOrgAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListOwnerOrgAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListOwnerOrgAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessType();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 business_type = 1;
 * @return {number}
 */
proto.api.ListOwnerOrgAdminRequest.prototype.getBusinessType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ListOwnerOrgAdminRequest} returns this
 */
proto.api.ListOwnerOrgAdminRequest.prototype.setBusinessType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListOwnerOrgAdminResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListOwnerOrgAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListOwnerOrgAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListOwnerOrgAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListOwnerOrgAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgsList: jspb.Message.toObjectList(msg.getOwnerOrgsList(),
    proto.api.ListOwnerOrgAdminResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListOwnerOrgAdminResponse}
 */
proto.api.ListOwnerOrgAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListOwnerOrgAdminResponse;
  return proto.api.ListOwnerOrgAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListOwnerOrgAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListOwnerOrgAdminResponse}
 */
proto.api.ListOwnerOrgAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ListOwnerOrgAdminResponse.Item;
      reader.readMessage(value,proto.api.ListOwnerOrgAdminResponse.Item.deserializeBinaryFromReader);
      msg.addOwnerOrgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListOwnerOrgAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListOwnerOrgAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListOwnerOrgAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListOwnerOrgAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.ListOwnerOrgAdminResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListOwnerOrgAdminResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListOwnerOrgAdminResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListOwnerOrgAdminResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListOwnerOrgAdminResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrg: (f = msg.getOwnerOrg()) && proto.api.OwnerOrgItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListOwnerOrgAdminResponse.Item}
 */
proto.api.ListOwnerOrgAdminResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListOwnerOrgAdminResponse.Item;
  return proto.api.ListOwnerOrgAdminResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListOwnerOrgAdminResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListOwnerOrgAdminResponse.Item}
 */
proto.api.ListOwnerOrgAdminResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.OwnerOrgItem;
      reader.readMessage(value,proto.api.OwnerOrgItem.deserializeBinaryFromReader);
      msg.setOwnerOrg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListOwnerOrgAdminResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListOwnerOrgAdminResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListOwnerOrgAdminResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListOwnerOrgAdminResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrg();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.OwnerOrgItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional OwnerOrgItem owner_org = 1;
 * @return {?proto.api.OwnerOrgItem}
 */
proto.api.ListOwnerOrgAdminResponse.Item.prototype.getOwnerOrg = function() {
  return /** @type{?proto.api.OwnerOrgItem} */ (
    jspb.Message.getWrapperField(this, proto.api.OwnerOrgItem, 1));
};


/**
 * @param {?proto.api.OwnerOrgItem|undefined} value
 * @return {!proto.api.ListOwnerOrgAdminResponse.Item} returns this
*/
proto.api.ListOwnerOrgAdminResponse.Item.prototype.setOwnerOrg = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListOwnerOrgAdminResponse.Item} returns this
 */
proto.api.ListOwnerOrgAdminResponse.Item.prototype.clearOwnerOrg = function() {
  return this.setOwnerOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListOwnerOrgAdminResponse.Item.prototype.hasOwnerOrg = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Item owner_orgs = 1;
 * @return {!Array<!proto.api.ListOwnerOrgAdminResponse.Item>}
 */
proto.api.ListOwnerOrgAdminResponse.prototype.getOwnerOrgsList = function() {
  return /** @type{!Array<!proto.api.ListOwnerOrgAdminResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListOwnerOrgAdminResponse.Item, 1));
};


/**
 * @param {!Array<!proto.api.ListOwnerOrgAdminResponse.Item>} value
 * @return {!proto.api.ListOwnerOrgAdminResponse} returns this
*/
proto.api.ListOwnerOrgAdminResponse.prototype.setOwnerOrgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.ListOwnerOrgAdminResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListOwnerOrgAdminResponse.Item}
 */
proto.api.ListOwnerOrgAdminResponse.prototype.addOwnerOrgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.ListOwnerOrgAdminResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListOwnerOrgAdminResponse} returns this
 */
proto.api.ListOwnerOrgAdminResponse.prototype.clearOwnerOrgsList = function() {
  return this.setOwnerOrgsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListOwnerOrgManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListOwnerOrgManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListOwnerOrgManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListOwnerOrgManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListOwnerOrgManagementRequest}
 */
proto.api.ListOwnerOrgManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListOwnerOrgManagementRequest;
  return proto.api.ListOwnerOrgManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListOwnerOrgManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListOwnerOrgManagementRequest}
 */
proto.api.ListOwnerOrgManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListOwnerOrgManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListOwnerOrgManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListOwnerOrgManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListOwnerOrgManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessType();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 business_type = 1;
 * @return {number}
 */
proto.api.ListOwnerOrgManagementRequest.prototype.getBusinessType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ListOwnerOrgManagementRequest} returns this
 */
proto.api.ListOwnerOrgManagementRequest.prototype.setBusinessType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.ListOwnerOrgManagementResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListOwnerOrgManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListOwnerOrgManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListOwnerOrgManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListOwnerOrgManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrgsList: jspb.Message.toObjectList(msg.getOwnerOrgsList(),
    proto.api.ListOwnerOrgManagementResponse.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListOwnerOrgManagementResponse}
 */
proto.api.ListOwnerOrgManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListOwnerOrgManagementResponse;
  return proto.api.ListOwnerOrgManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListOwnerOrgManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListOwnerOrgManagementResponse}
 */
proto.api.ListOwnerOrgManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.ListOwnerOrgManagementResponse.Item;
      reader.readMessage(value,proto.api.ListOwnerOrgManagementResponse.Item.deserializeBinaryFromReader);
      msg.addOwnerOrgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListOwnerOrgManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListOwnerOrgManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListOwnerOrgManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListOwnerOrgManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.ListOwnerOrgManagementResponse.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ListOwnerOrgManagementResponse.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ListOwnerOrgManagementResponse.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ListOwnerOrgManagementResponse.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListOwnerOrgManagementResponse.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerOrg: (f = msg.getOwnerOrg()) && proto.api.OwnerOrgItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ListOwnerOrgManagementResponse.Item}
 */
proto.api.ListOwnerOrgManagementResponse.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ListOwnerOrgManagementResponse.Item;
  return proto.api.ListOwnerOrgManagementResponse.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ListOwnerOrgManagementResponse.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ListOwnerOrgManagementResponse.Item}
 */
proto.api.ListOwnerOrgManagementResponse.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.OwnerOrgItem;
      reader.readMessage(value,proto.api.OwnerOrgItem.deserializeBinaryFromReader);
      msg.setOwnerOrg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ListOwnerOrgManagementResponse.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ListOwnerOrgManagementResponse.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ListOwnerOrgManagementResponse.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ListOwnerOrgManagementResponse.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerOrg();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.OwnerOrgItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional OwnerOrgItem owner_org = 1;
 * @return {?proto.api.OwnerOrgItem}
 */
proto.api.ListOwnerOrgManagementResponse.Item.prototype.getOwnerOrg = function() {
  return /** @type{?proto.api.OwnerOrgItem} */ (
    jspb.Message.getWrapperField(this, proto.api.OwnerOrgItem, 1));
};


/**
 * @param {?proto.api.OwnerOrgItem|undefined} value
 * @return {!proto.api.ListOwnerOrgManagementResponse.Item} returns this
*/
proto.api.ListOwnerOrgManagementResponse.Item.prototype.setOwnerOrg = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ListOwnerOrgManagementResponse.Item} returns this
 */
proto.api.ListOwnerOrgManagementResponse.Item.prototype.clearOwnerOrg = function() {
  return this.setOwnerOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ListOwnerOrgManagementResponse.Item.prototype.hasOwnerOrg = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Item owner_orgs = 1;
 * @return {!Array<!proto.api.ListOwnerOrgManagementResponse.Item>}
 */
proto.api.ListOwnerOrgManagementResponse.prototype.getOwnerOrgsList = function() {
  return /** @type{!Array<!proto.api.ListOwnerOrgManagementResponse.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.ListOwnerOrgManagementResponse.Item, 1));
};


/**
 * @param {!Array<!proto.api.ListOwnerOrgManagementResponse.Item>} value
 * @return {!proto.api.ListOwnerOrgManagementResponse} returns this
*/
proto.api.ListOwnerOrgManagementResponse.prototype.setOwnerOrgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.ListOwnerOrgManagementResponse.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.ListOwnerOrgManagementResponse.Item}
 */
proto.api.ListOwnerOrgManagementResponse.prototype.addOwnerOrgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.ListOwnerOrgManagementResponse.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.ListOwnerOrgManagementResponse} returns this
 */
proto.api.ListOwnerOrgManagementResponse.prototype.clearOwnerOrgsList = function() {
  return this.setOwnerOrgsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.NullableOwnerOrgId.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.api.NullableOwnerOrgId.IdCase = {
  ID_NOT_SET: 0,
  NULL: 1,
  VAL: 2
};

/**
 * @return {proto.api.NullableOwnerOrgId.IdCase}
 */
proto.api.NullableOwnerOrgId.prototype.getIdCase = function() {
  return /** @type {proto.api.NullableOwnerOrgId.IdCase} */(jspb.Message.computeOneofCase(this, proto.api.NullableOwnerOrgId.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.NullableOwnerOrgId.prototype.toObject = function(opt_includeInstance) {
  return proto.api.NullableOwnerOrgId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.NullableOwnerOrgId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.NullableOwnerOrgId.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_null: jspb.Message.getFieldWithDefault(msg, 1, 0),
    val: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.NullableOwnerOrgId}
 */
proto.api.NullableOwnerOrgId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.NullableOwnerOrgId;
  return proto.api.NullableOwnerOrgId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.NullableOwnerOrgId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.NullableOwnerOrgId}
 */
proto.api.NullableOwnerOrgId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.protobuf.NullValue} */ (reader.readEnum());
      msg.setNull(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.NullableOwnerOrgId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.NullableOwnerOrgId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.NullableOwnerOrgId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.NullableOwnerOrgId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.NullValue null = 1;
 * @return {!proto.google.protobuf.NullValue}
 */
proto.api.NullableOwnerOrgId.prototype.getNull = function() {
  return /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.protobuf.NullValue} value
 * @return {!proto.api.NullableOwnerOrgId} returns this
 */
proto.api.NullableOwnerOrgId.prototype.setNull = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.api.NullableOwnerOrgId.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.NullableOwnerOrgId} returns this
 */
proto.api.NullableOwnerOrgId.prototype.clearNull = function() {
  return jspb.Message.setOneofField(this, 1, proto.api.NullableOwnerOrgId.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.NullableOwnerOrgId.prototype.hasNull = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string val = 2;
 * @return {string}
 */
proto.api.NullableOwnerOrgId.prototype.getVal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NullableOwnerOrgId} returns this
 */
proto.api.NullableOwnerOrgId.prototype.setVal = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.api.NullableOwnerOrgId.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.NullableOwnerOrgId} returns this
 */
proto.api.NullableOwnerOrgId.prototype.clearVal = function() {
  return jspb.Message.setOneofField(this, 2, proto.api.NullableOwnerOrgId.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.NullableOwnerOrgId.prototype.hasVal = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.NullableClientOrgId.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.api.NullableClientOrgId.IdCase = {
  ID_NOT_SET: 0,
  NULL: 1,
  VAL: 2
};

/**
 * @return {proto.api.NullableClientOrgId.IdCase}
 */
proto.api.NullableClientOrgId.prototype.getIdCase = function() {
  return /** @type {proto.api.NullableClientOrgId.IdCase} */(jspb.Message.computeOneofCase(this, proto.api.NullableClientOrgId.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.NullableClientOrgId.prototype.toObject = function(opt_includeInstance) {
  return proto.api.NullableClientOrgId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.NullableClientOrgId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.NullableClientOrgId.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_null: jspb.Message.getFieldWithDefault(msg, 1, 0),
    val: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.NullableClientOrgId}
 */
proto.api.NullableClientOrgId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.NullableClientOrgId;
  return proto.api.NullableClientOrgId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.NullableClientOrgId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.NullableClientOrgId}
 */
proto.api.NullableClientOrgId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.google.protobuf.NullValue} */ (reader.readEnum());
      msg.setNull(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.NullableClientOrgId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.NullableClientOrgId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.NullableClientOrgId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.NullableClientOrgId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.NullValue null = 1;
 * @return {!proto.google.protobuf.NullValue}
 */
proto.api.NullableClientOrgId.prototype.getNull = function() {
  return /** @type {!proto.google.protobuf.NullValue} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.google.protobuf.NullValue} value
 * @return {!proto.api.NullableClientOrgId} returns this
 */
proto.api.NullableClientOrgId.prototype.setNull = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.api.NullableClientOrgId.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.NullableClientOrgId} returns this
 */
proto.api.NullableClientOrgId.prototype.clearNull = function() {
  return jspb.Message.setOneofField(this, 1, proto.api.NullableClientOrgId.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.NullableClientOrgId.prototype.hasNull = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string val = 2;
 * @return {string}
 */
proto.api.NullableClientOrgId.prototype.getVal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.NullableClientOrgId} returns this
 */
proto.api.NullableClientOrgId.prototype.setVal = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.api.NullableClientOrgId.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.NullableClientOrgId} returns this
 */
proto.api.NullableClientOrgId.prototype.clearVal = function() {
  return jspb.Message.setOneofField(this, 2, proto.api.NullableClientOrgId.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.NullableClientOrgId.prototype.hasVal = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.OwnerOrgItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.OwnerOrgItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.OwnerOrgItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.OwnerOrgItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && proto.api.ClientAddress.toObject(includeInstance, f),
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orgType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    businessType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.OwnerOrgItem}
 */
proto.api.OwnerOrgItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.OwnerOrgItem;
  return proto.api.OwnerOrgItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.OwnerOrgItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.OwnerOrgItem}
 */
proto.api.OwnerOrgItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.api.ClientAddress;
      reader.readMessage(value,proto.api.ClientAddress.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.OwnerOrgItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.OwnerOrgItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.OwnerOrgItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.OwnerOrgItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.ClientAddress.serializeBinaryToWriter
    );
  }
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrgType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBusinessType();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.OwnerOrgItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.OwnerOrgItem} returns this
 */
proto.api.OwnerOrgItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.OwnerOrgItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.OwnerOrgItem} returns this
 */
proto.api.OwnerOrgItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ClientAddress address = 3;
 * @return {?proto.api.ClientAddress}
 */
proto.api.OwnerOrgItem.prototype.getAddress = function() {
  return /** @type{?proto.api.ClientAddress} */ (
    jspb.Message.getWrapperField(this, proto.api.ClientAddress, 3));
};


/**
 * @param {?proto.api.ClientAddress|undefined} value
 * @return {!proto.api.OwnerOrgItem} returns this
*/
proto.api.OwnerOrgItem.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.OwnerOrgItem} returns this
 */
proto.api.OwnerOrgItem.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.OwnerOrgItem.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string owner_org_id = 4;
 * @return {string}
 */
proto.api.OwnerOrgItem.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.OwnerOrgItem} returns this
 */
proto.api.OwnerOrgItem.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string org_type = 5;
 * @return {string}
 */
proto.api.OwnerOrgItem.prototype.getOrgType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.OwnerOrgItem} returns this
 */
proto.api.OwnerOrgItem.prototype.setOrgType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 business_type = 6;
 * @return {number}
 */
proto.api.OwnerOrgItem.prototype.getBusinessType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.OwnerOrgItem} returns this
 */
proto.api.OwnerOrgItem.prototype.setBusinessType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateOwnerOrgItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateOwnerOrgItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateOwnerOrgItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateOwnerOrgItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && proto.api.ClientAddress.toObject(includeInstance, f),
    ownerOrgId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orgType: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateOwnerOrgItem}
 */
proto.api.UpdateOwnerOrgItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateOwnerOrgItem;
  return proto.api.UpdateOwnerOrgItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateOwnerOrgItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateOwnerOrgItem}
 */
proto.api.UpdateOwnerOrgItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.api.ClientAddress;
      reader.readMessage(value,proto.api.ClientAddress.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateOwnerOrgItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateOwnerOrgItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateOwnerOrgItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateOwnerOrgItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.ClientAddress.serializeBinaryToWriter
    );
  }
  f = message.getOwnerOrgId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrgType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.UpdateOwnerOrgItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateOwnerOrgItem} returns this
 */
proto.api.UpdateOwnerOrgItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ClientAddress address = 3;
 * @return {?proto.api.ClientAddress}
 */
proto.api.UpdateOwnerOrgItem.prototype.getAddress = function() {
  return /** @type{?proto.api.ClientAddress} */ (
    jspb.Message.getWrapperField(this, proto.api.ClientAddress, 3));
};


/**
 * @param {?proto.api.ClientAddress|undefined} value
 * @return {!proto.api.UpdateOwnerOrgItem} returns this
*/
proto.api.UpdateOwnerOrgItem.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateOwnerOrgItem} returns this
 */
proto.api.UpdateOwnerOrgItem.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateOwnerOrgItem.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string owner_org_id = 4;
 * @return {string}
 */
proto.api.UpdateOwnerOrgItem.prototype.getOwnerOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateOwnerOrgItem} returns this
 */
proto.api.UpdateOwnerOrgItem.prototype.setOwnerOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string org_type = 5;
 * @return {string}
 */
proto.api.UpdateOwnerOrgItem.prototype.getOrgType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateOwnerOrgItem} returns this
 */
proto.api.UpdateOwnerOrgItem.prototype.setOrgType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ClientOwnerItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ClientOwnerItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ClientOwnerItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ClientOwnerItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    nationalId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    address: (f = msg.getAddress()) && proto.api.ClientAddress.toObject(includeInstance, f),
    businessType: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ClientOwnerItem}
 */
proto.api.ClientOwnerItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ClientOwnerItem;
  return proto.api.ClientOwnerItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ClientOwnerItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ClientOwnerItem}
 */
proto.api.ClientOwnerItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = new proto.api.ClientAddress;
      reader.readMessage(value,proto.api.ClientAddress.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ClientOwnerItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ClientOwnerItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ClientOwnerItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ClientOwnerItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNationalId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.ClientAddress.serializeBinaryToWriter
    );
  }
  f = message.getBusinessType();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.ClientOwnerItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ClientOwnerItem} returns this
 */
proto.api.ClientOwnerItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.api.ClientOwnerItem.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ClientOwnerItem} returns this
 */
proto.api.ClientOwnerItem.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.api.ClientOwnerItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ClientOwnerItem} returns this
 */
proto.api.ClientOwnerItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string national_id = 4;
 * @return {string}
 */
proto.api.ClientOwnerItem.prototype.getNationalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ClientOwnerItem} returns this
 */
proto.api.ClientOwnerItem.prototype.setNationalId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.api.ClientOwnerItem.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ClientOwnerItem} returns this
 */
proto.api.ClientOwnerItem.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ClientAddress address = 6;
 * @return {?proto.api.ClientAddress}
 */
proto.api.ClientOwnerItem.prototype.getAddress = function() {
  return /** @type{?proto.api.ClientAddress} */ (
    jspb.Message.getWrapperField(this, proto.api.ClientAddress, 6));
};


/**
 * @param {?proto.api.ClientAddress|undefined} value
 * @return {!proto.api.ClientOwnerItem} returns this
*/
proto.api.ClientOwnerItem.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ClientOwnerItem} returns this
 */
proto.api.ClientOwnerItem.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ClientOwnerItem.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 business_type = 7;
 * @return {number}
 */
proto.api.ClientOwnerItem.prototype.getBusinessType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.ClientOwnerItem} returns this
 */
proto.api.ClientOwnerItem.prototype.setBusinessType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateClientOwnerItem.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateClientOwnerItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateClientOwnerItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateClientOwnerItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nationalId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: (f = msg.getAddress()) && proto.api.ClientAddress.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateClientOwnerItem}
 */
proto.api.UpdateClientOwnerItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateClientOwnerItem;
  return proto.api.UpdateClientOwnerItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateClientOwnerItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateClientOwnerItem}
 */
proto.api.UpdateClientOwnerItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = new proto.api.ClientAddress;
      reader.readMessage(value,proto.api.ClientAddress.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateClientOwnerItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateClientOwnerItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateClientOwnerItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateClientOwnerItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNationalId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.ClientAddress.serializeBinaryToWriter
    );
  }
};


/**
 * optional string phone_number = 1;
 * @return {string}
 */
proto.api.UpdateClientOwnerItem.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateClientOwnerItem} returns this
 */
proto.api.UpdateClientOwnerItem.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.UpdateClientOwnerItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateClientOwnerItem} returns this
 */
proto.api.UpdateClientOwnerItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string national_id = 3;
 * @return {string}
 */
proto.api.UpdateClientOwnerItem.prototype.getNationalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateClientOwnerItem} returns this
 */
proto.api.UpdateClientOwnerItem.prototype.setNationalId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.api.UpdateClientOwnerItem.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UpdateClientOwnerItem} returns this
 */
proto.api.UpdateClientOwnerItem.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ClientAddress address = 6;
 * @return {?proto.api.ClientAddress}
 */
proto.api.UpdateClientOwnerItem.prototype.getAddress = function() {
  return /** @type{?proto.api.ClientAddress} */ (
    jspb.Message.getWrapperField(this, proto.api.ClientAddress, 6));
};


/**
 * @param {?proto.api.ClientAddress|undefined} value
 * @return {!proto.api.UpdateClientOwnerItem} returns this
*/
proto.api.UpdateClientOwnerItem.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateClientOwnerItem} returns this
 */
proto.api.UpdateClientOwnerItem.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateClientOwnerItem.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 6) != null;
};


goog.object.extend(exports, proto.api);
