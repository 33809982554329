import { useState } from "react";
import { Table, Modal } from "antd";
import { TableProps } from "antd/lib/table";
import { unresolvedissuesData } from "./__mock__";
import {
  renderIssueId,
  renderVehicleId,
  renderIssueType,
  renderDueDate,
} from "./cell-renders";

const columns: TableProps<any>["columns"] = [
  {
    title: "Issue ID",
    dataIndex: "issue_id",
    render: renderIssueId,
    // className: "open-link",
  },
  {
    title: "Vehicle ID",
    dataIndex: "vehicle_id",
    render: renderVehicleId,
    // className: "open-link",
  },
  {
    title: "Issue Type",
    dataIndex: "issue_type",
    align: "center",
    render: renderIssueType,
  },
  {
    title: "Due Date",
    dataIndex: "due_date",
    align: "center",
    render: renderDueDate,
  },
];

export default function UnresolvedIssues() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Open the modal when "View All" is clicked
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Close the modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Show only the top 3 issues on the main table
  const top3Issues = unresolvedissuesData.slice(0, 3);

  // Define columns for the main table without the Due Date
  const mainTableColumns = (columns || []).filter(column => column.title !== "Due Date");

  return (
    <>
      <section className="dashboard-table-header">
        <h3 style={{ margin: 0 }}>Pending Issues</h3>
        <a className="dashboard-table-link" onClick={showModal}>
          View All
        </a>
      </section>

      <Table
        className="--top5-table custom-scrollbar"
        // rowClassName="dashed-border "
        dataSource={top3Issues} // Show top 3 issues only
        columns={mainTableColumns} // Use the main table columns without the Due Date
        pagination={false}
        scroll={{ y: 200 }}
      />

      {/* Modal to display all data */}
      <Modal
        title="All Pending Issues"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800} // Adjust width as needed
      >
        <Table
          className="--top5-table custom-scrollbar"
          dataSource={unresolvedissuesData} // Show all data
          columns={columns} // Use all columns for the modal
          pagination={false}
          scroll={{ y: 300 }} // Adjust scroll height as needed
        />
      </Modal>
    </>
  );
}
