import React, { useState } from "react";
import { PieChart, PieChartData } from "@k2/rv-viz";
import { Modal, Table } from "antd";
import { Select } from "antd"; // Import Ant Design's Select component

const { Option } = Select; // Destructure Option from Select

export default function IssueRenderChart() {
  const [selectedFilter, setSelectedFilter] = useState("this month"); // Default filter value
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);


  type CategoryKeys = 'Breakdown' | 'Accident' | 'Roadside Breakdown' | 'Service' | 'Vehicle Missing';

  const categoryData: Record<CategoryKeys, { issue_id: string; vehicle_id: string; issue_type: string; due_date: string; }[]> = {
    Breakdown: [
      { issue_id: "I001", vehicle_id: "V001", issue_type: "Engine failure", due_date: "2023-12-01" },
      { issue_id: "I002", vehicle_id: "V002", issue_type: "Flat tire", due_date: "2023-12-03" },
    ],
    Accident: [
      { issue_id: "I003", vehicle_id: "V003", issue_type: "Collision with a tree", due_date: "2023-12-04" },
    ],
    "Roadside Breakdown": [
      { issue_id: "I004", vehicle_id: "V004", issue_type: "Battery issue", due_date: "2023-12-05" },
    ],
    Service: [
      { issue_id: "I005", vehicle_id: "V005", issue_type: "Routine maintenance", due_date: "2023-12-06" },
    ],
    "Vehicle Missing": [
      { issue_id: "I006", vehicle_id: "V006", issue_type: "Stolen vehicle", due_date: "2023-12-07" },
    ],
  };

  // Dynamically update pie chart data based on categoryData
  const pieData = Object.keys(categoryData).map((category) => ({
    value: categoryData[category as CategoryKeys].length, // Number of issues in each category
    label: category,
  }));

  // Handle the Pie Chart click event
  const handlePieClick = (event: { data: PieChartData }) => {
    const category = event.data.label;
    setSelectedCategory(category);
    setIsModalVisible(true);
  };

  const filteredCategoryData = selectedCategory ? categoryData[selectedCategory as CategoryKeys] : [];

  const columns = [
    {
      title: "Issue ID",
      dataIndex: "issue_id",
    },
    {
      title: "Vehicle ID",
      dataIndex: "vehicle_id",
    },
    {
      title: "Issue Type",
      dataIndex: "issue_type",
      align: "center" as "center" | undefined,
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      align: "center" as "center" | undefined,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <h3 style={{ margin: 0 }}>Summary of Issues</h3>
        {/* Filter selection can be added here if needed */}
      </div>

      <div className="pie-chart-wrapper">
        <div className="pie-chart-container">
          <PieChart
            legends={false}
            colors={{
              dark: ["#70b857", "#4580d9", "#ed2020", "#ecc600", "#f0a600"],
              light: ["#70b857", "#4580d9", "#ed2020", "#ecc600", "#f0a600"],
            }}
            data={pieData}
            radial={{
              anglePadding: 2,
              innerRadius: 0.9,
            }}
            title={""}
            classes={{
              label: "label-container",
            }}
            onClick={handlePieClick} // Click event to handle pie chart clicks
            componentStates={{
              blank: <figure className="empty-state-widget">No Data</figure>,
            }}
          />
        </div>
      </div>

      {/* Modal to display category data */}
      <Modal
        title={`Category: ${selectedCategory}`}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800} // Adjust width as needed
      >
        <div>
          {filteredCategoryData.length > 0 ? (
            <Table
              dataSource={filteredCategoryData}
              columns={columns}
              pagination={false}
              scroll={{ y: 300 }} // Set the scroll height if needed
            />
          ) : (
            <p>No data available for this category.</p>
          )}
        </div>
      </Modal>
    </div>
  );
}
function setSelectedCategory(category: string) {
  throw new Error("Function not implemented.");
}

