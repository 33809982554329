import { Table } from "components/base";
import React, { useState } from "react";
import { TableProps } from "antd/lib/table";
import { Select } from "antd";
const { Option } = Select;

const columns: TableProps<any>["columns"] = [
  { title: "Rider Name", dataIndex: "rider_id" },
  { title: "Total Rides", dataIndex: "rides" },
  { title: "Driving Distance (KMs)", dataIndex: "distance" },
  { title: "Overall Score", dataIndex: "range", align: "center" },
];

interface RiderScoreTableProps {
  data?: any[]; // Make data optional
}

export default function RiderScoreTable({ data = [] }: RiderScoreTableProps) {
  const [selectedFilter, setSelectedFilter] = useState("26-01-2025 to 08-02-2025"); // Default filter value

  // Handle filter change
  const handleFilterChange = (value: string) => {
    setSelectedFilter(value);
    console.log("Selected filter:", value);
  };

  return (
    <>
      <section className="dashboard-table-header">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <h3 style={{ margin: 0 }}></h3>
          {/* <Select
            id="filter"
            value={selectedFilter}
            onChange={handleFilterChange}
            style={{ width: 210 }} // Adjust width as needed
          >
            <Option value="01-01-2025 to 11-01-2025">01-01-2025 to 11-01-2025</Option>
            <Option value="12-01-2025 to 25-01-2025">12-01-2025 to 25-01-2025</Option>
            <Option value="26-01-2025 to 08-02-2025">26-01-2025 to 08-02-2025</Option>
          </Select> */}
        </div>
      </section>

      {/* Display a message if data is not provided */}
      {data.length === 0 ? (
        <p>No data available for the selected filter.</p>
      ) : (
        <Table
          customClasses=""
          dataSource={data}
          columns={columns}
          pagination={false}
          scroll={{ y: 285 }}
        />
      )}
    </>
  );
}
