import {
  ColumnChart,
  LineChart,
  BarChart,
  PieChart,
  VehiclesRenderChart,
  IssuesPieChart,
  UnresolvedIssues,
  TripsTable,
  AlertStats,
  RiderStatsTable,
  RiderScoreTable,
  UnassignedVehicles,
  FinancialWidget,
  FixedPlacementBar,
  RiderScoreChart,
} from "components";
import {
  HorizontalTiles,
  IssueTileContainer,
  VehicleTileContainer,
} from "components/tab-content/dashboard-content/tile-containers";
import FinancialsTileContainer from "components/tab-content/dashboard-content/tile-containers/financials-container";
import { taskDistributionIcon } from "svgs";
import { BarDataItem, SelectType } from "types";
import { FilterItem } from "types/store";
import {
  CLIENT_DOMAIN_ID_DEV,
  CLIENT_DOMAIN_ID_PROD,
} from "./constants";
import {NotificationFilter} from "../protobuf/fcm_notification_service_pb";

export function getRandomSvg(arr: JSX.Element[]) {
  if (!arr.length) return <div />;

  return arr[Math.floor(Math.random() * arr.length)];
}

export function getRandomString(arr: string[], index?: number) {
  if (!arr.length) return "";
  if (index) return arr[index];
  return arr[Math.floor(Math.random() * arr.length)];
}

export function getRandomNumber(minNum: number) {
  return Math.floor(minNum + Math.random() * 9 * minNum);
}

export function classNames(classesObj: Object) {
  const classes = Object.entries(classesObj).map(([key, value]) =>
    Boolean(value) ? key : undefined
  );
  return classes.filter((cls) => Boolean(cls)).join(" ");
}

export function range(length: number) {
  return Array(length).fill(0);
}

const RenderCustomTitle = () => (
  <article className="custom-chart-header">
    <section className="left-side">
      <strong>Tasks Distribution</strong>
    </section>
    <section className="right-side">
      <span>{taskDistributionIcon}</span>
    </section>
  </article>
);


export function getDashboardWidget(widgetName: string, selectedDate: string, organizationId: string) {
  switch (widgetName) {
    case "issue-count":
      return LineChart;
    case "issue-by-status":
      return ColumnChart;
    case "bar-chart":
      return BarChart;
    case "vehicles-by-status":
      return PieChart;
    case "vehicles-with-filter":
      return VehiclesRenderChart;
    case "issues-by-status":
      return IssuesPieChart;
    case "issue-tiles":
      return IssueTileContainer;
      case "horizontal-tiles":
        return HorizontalTiles;
    case "vehicle-tiles":
      return VehicleTileContainer;
    case "financials-tiles":
      return FinancialsTileContainer;
    case "unresolved-issues":
      return UnresolvedIssues;
    case "vehicle-trips":
      return TripsTable;
    case "alert-stats":
      return AlertStats;
    case "rider-stats":
      return RiderStatsTable;
    case "rider-score":
      return RiderScoreTable;
    case "unassigned-vehicles":
      return UnassignedVehicles;
    case "traffic-fines-count":
    case "excess-insurance-count":
    case "additional-costs":
    case "repair-invoices-count":
    case "traffic-salik-count":
      return () => <FinancialWidget name={widgetName} selectedDate={selectedDate} organizationId={organizationId}/>;
    case "tasks-distribution":
      return () => <FixedPlacementBar title={<RenderCustomTitle />} />;
    case "rider-score-chart":
      return RiderScoreChart;
    default:
      return () => <div data-testid="empty-widget" />;
  }
}

export function getRequiredMeta(
  auth_prod: string,
  client_domain_id_dev: typeof CLIENT_DOMAIN_ID_DEV,
  client_domain_id_prod: typeof CLIENT_DOMAIN_ID_PROD
) {
  if (process.env.NODE_ENV === "development") {
    return {
      CLIENT_DOMAIN_ID: client_domain_id_dev,
      AUTHORIZATION: auth_prod,
    };
  } else {
    return {
      CLIENT_DOMAIN_ID: client_domain_id_prod,
      AUTHORIZATION: auth_prod,
    };
  }
}

export function getValueArrFromFilterOptions(
  name: string,
  options: FilterItem
) {
  return options[name]?.map((filter) => filter.value) || [];
}

export function getAllCheckedStatus(updated: FilterItem, original: FilterItem) {
  const updatedArr = Object.values(updated).flat();
  const originalArr = Object.values(original).flat();

  return updatedArr.length === originalArr.length;
}

export function getIndeterminate(updated: FilterItem, original: FilterItem) {
  const updatedArr = Object.values(updated).flat();
  const originalArr = Object.values(original).flat();

  return updatedArr.length > 0 && originalArr.length !== updatedArr.length;
}

export function getSecondsFromMillis(milli: number) {
  return milli / 1000;
}

export function getMillisFromSeconds(sec: number) {
  return sec * 1000;
}

export function getDateFromSeconds(sec?: number) {
  return sec ? new Date(getMillisFromSeconds(sec)) : new Date(Date.now());
}

export function getSecondsFromDate(date: Date) {
  return getSecondsFromMillis(date.getTime());
}

export function sleep(ms: number) {
  if (!ms) return new Promise((value) => value);
  return new Promise((value) => setTimeout(value, ms));
}

export function fileToByteArray(file: File) {
  return new Promise((resolve: (value: Uint8Array) => void, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target?.readyState == FileReader.DONE) {
          const arrayBuffer = evt.target.result as ArrayBuffer;
          const array = new Uint8Array(arrayBuffer);
          resolve(array);
        }
      };
    } catch (e) {
      reject(e);
    }
  });
}

export function blobToFile (theBlob: Blob, fileName:string): File {
  var b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return theBlob as File;
}

export function dialCodePrefix(countryCode: string, prefix: string = "+") {
  return `${prefix}${countryCode}`;
}

export function numberWithoutCode(phoneNo: string, dialCode: string) {
  return phoneNo.replace(dialCode, "");
}

export function splitFullName(name: string) {
  const nameArr = name.split(" ");
  const [firstName, ...lastName] = nameArr;

  return {
    firstName: firstName,
    lastName: lastName.join(" "),
  };
}

export function getFullName(firstName: string = "", lastName: string = "") {
  return `${firstName} ${lastName}`;
}

export function isFormDisabled(obj: Object = {}) {
  return Object.keys(obj).length > 0;
}

export function getValidVehicle(vehicleId: string, vehicles: SelectType) {
  return vehicles.find((vehicle) => vehicle.value === vehicleId)
    ? vehicleId
    : "";
}

export function getHref(bytes?: Uint8Array | string, type: string = "") {
  return window.URL.createObjectURL(
    new Blob([bytes || new File([], "")], {
      type,
    })
  );
}

export function getPercentageValue(percent: number, total: number) {
  return (percent / 100) * total || 0;
}

export function getMaxBar(data: BarDataItem[]) {
  return data.reduce((prev, current) => {
    if (prev < current.totalValue) {
      return current.totalValue;
    }
    return prev;
  }, 0);
}


export function getNotificationFiltersValues(updatedFilter:FilterItem)
{
  let notificationFilters = new NotificationFilter()
  for (const key in updatedFilter) {
    if (updatedFilter.hasOwnProperty(key) && key != "Priority" && key != "Status") {
      // If the value is an array, loop through its elements
      if (Array.isArray(updatedFilter[key])) {
        updatedFilter[key].forEach((item, index) => {
           notificationFilters.addSubCategoryId(item.value)
        });
      }
    }
  }
  return notificationFilters;
}
