import { Table, TablePagination } from "components/base";
import { TableProps } from "antd/lib/table";
import moment, { Moment } from "moment";
import { forwardRef, useImperativeHandle } from "react";

interface TripsContentProps {
  selectedDate: Moment | null;
  searchText: string;
}

const columns: TableProps<any>["columns"] = [
  { title: "Vehicle ID", dataIndex: "vehicle_id" },
  { title: "Rider Name", dataIndex: "rider_name" },
  { title: "Trip Start", dataIndex: "start_time" },
  { title: "Trip End", dataIndex: "end_time" },
  { title: "Distance (KMs)", dataIndex: "kms" },
  { title: "Rider Score", dataIndex: "rider_score", align: "right" },
];

const data = [
  { key: 0, kms: "1124", rider_score: "02", start_time:"14-01-2025 8:32 am",end_time:"14-01-2025 10:42 am", vehicle_id: "ZUM2677", rider_name: "John Doe", },
  { key: 1, kms: "2214", rider_score: "03",  start_time:"14-02-2025 8:32 am",end_time:"14-02-2025 10:42 am",vehicle_id: "ZUM34829", rider_name: "Ahmed Ali", },
  { key: 2, kms: "2344", rider_score: "05",  start_time:"14-01-2025 8:32 am",end_time:"14-01-2025 10:42 am",vehicle_id: "ZUM27825", rider_name: "John Doe", },
  {
    key: 3,
    kms: "3332",
    rider_score: "05",
    start_time:"14-02-2025 8:32 am",end_time:"14-02-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "Hassan Ali",
  },
  {
    key: 4,
    kms: "3332",
    rider_score: "02",
    start_time:"14-02-2025 8:32 am",end_time:"14-02-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "Haris Ali",
  },
  {
    key: 5,
    kms: "3332",
    rider_score: "02",
    start_time:"14-02-2025 8:32 am",end_time:"14-02-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "Haris Ali",
  },
  {
    key: 6,
    kms: "3332",
    rider_score: "02",
    start_time:"14-02-2025 8:32 am",end_time:"14-02-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "Waqar Ali",
  },
  {
    key: 7,
    kms: "3332",
    rider_score: "03",
    start_time:"14-02-2025 8:32 am",end_time:"14-02-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "Tariq Ali",
  },
  {
    key: 8,
    kms: "3332",
    rider_score: "03",
    start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "John Doe",
  },
  {
    key: 9,
    kms: "3332",
    rider_score: "02",
    start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "John Doe",
  },
  {
    key: 10,
    kms: "3332",
    rider_score: "10",
    start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "John Doe",
  },
  {
    key: 11,
    kms: "3332",
    rider_score: "08",
    start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "John Doe",
  },
  {
    key: 12,
    kms: "3332",
    rider_score: "09",
    start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "John Doe",
  },
  {
    key: 13,
    kms: "3332",
    rider_score: "07",
    start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "John Doe",
  },
];


const csvColumns = [
  { header: "Vehicle ID", key: "vehicle_id" },
  { header: "Rider Name", key: "rider_name" },
  { header: "Start Trip", key: "start_time" },
  { header: "End Trip", key: "end_time" },
  { header: "KMs", key: "kms" },
  { header: "Rider Score", key: "rider_score" },
];

const convertToCSV = (data: any[]) => {
  if (!data.length) return "";
  const headers = csvColumns.map((col) => col.header).join(",");
  const csvRows = [headers];

  data.forEach((row) => {
    const values = csvColumns.map((col) => {
      let value = row[col.key] || "";
      if (col.key === "start_time" || col.key === "end_time") {
        value = `="${value}"`;
      } else {
        value = `"${value}"`;
      }
      return value;
    });
    csvRows.push(values.join(","));
  });

  return csvRows.join("\n");
};

const TripsContent = forwardRef(({ selectedDate, searchText }: TripsContentProps, ref) => {
  const filteredData = data.filter((trip) => {
    const matchesSearchText = trip.vehicle_id.toLowerCase().includes(searchText.toLowerCase()) ||
    trip.rider_name.toLowerCase().includes(searchText.toLowerCase());

    const startTimeDate = moment(trip.start_time, "DD-MM-YYYY h:mm a");

    const matchesDate = selectedDate
      ? startTimeDate.isSame(selectedDate, "day") // Compare only the date part (ignoring time)
      : true;

    return matchesSearchText && matchesDate;
  });

  const handleDownloadCsv = () => {
    const csvString = convertToCSV(filteredData);
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "SummaryOfTrips.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Expose handleDownloadCsv to parent component
  useImperativeHandle(ref, () => ({
    handleDownloadCsv,
  }));

  return (
    <>
      <Table
        customClasses=""
        dataSource={filteredData}
        columns={columns}
        pagination={false}
        scroll={{ y: "70vh" }}
      />
     
    </>
  );
});

export default TripsContent;