/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.2
// source: user_stats_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as user_stats_service_pb from './user_stats_service_pb';


export class UserStatsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetUserStats = new grpcWeb.MethodDescriptor(
    '/api.UserStatsService/GetUserStats',
    grpcWeb.MethodType.UNARY,
    user_stats_service_pb.GetUserStatsRequest,
    user_stats_service_pb.GetUserStatsResponse,
    (request: user_stats_service_pb.GetUserStatsRequest) => {
      return request.serializeBinary();
    },
    user_stats_service_pb.GetUserStatsResponse.deserializeBinary
  );

  getUserStats(
    request: user_stats_service_pb.GetUserStatsRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_stats_service_pb.GetUserStatsResponse>;

  getUserStats(
    request: user_stats_service_pb.GetUserStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetUserStatsResponse) => void): grpcWeb.ClientReadableStream<user_stats_service_pb.GetUserStatsResponse>;

  getUserStats(
    request: user_stats_service_pb.GetUserStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetUserStatsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserStatsService/GetUserStats',
        request,
        metadata || {},
        this.methodDescriptorGetUserStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserStatsService/GetUserStats',
    request,
    metadata || {},
    this.methodDescriptorGetUserStats);
  }

  methodDescriptorGetRiderScore = new grpcWeb.MethodDescriptor(
    '/api.UserStatsService/GetRiderScore',
    grpcWeb.MethodType.UNARY,
    user_stats_service_pb.GetRiderScoreRequest,
    user_stats_service_pb.GetRiderScoreResponse,
    (request: user_stats_service_pb.GetRiderScoreRequest) => {
      return request.serializeBinary();
    },
    user_stats_service_pb.GetRiderScoreResponse.deserializeBinary
  );

  getRiderScore(
    request: user_stats_service_pb.GetRiderScoreRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_stats_service_pb.GetRiderScoreResponse>;

  getRiderScore(
    request: user_stats_service_pb.GetRiderScoreRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetRiderScoreResponse) => void): grpcWeb.ClientReadableStream<user_stats_service_pb.GetRiderScoreResponse>;

  getRiderScore(
    request: user_stats_service_pb.GetRiderScoreRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetRiderScoreResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserStatsService/GetRiderScore',
        request,
        metadata || {},
        this.methodDescriptorGetRiderScore,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserStatsService/GetRiderScore',
    request,
    metadata || {},
    this.methodDescriptorGetRiderScore);
  }

  methodDescriptorGetTotalMileage = new grpcWeb.MethodDescriptor(
    '/api.UserStatsService/GetTotalMileage',
    grpcWeb.MethodType.UNARY,
    user_stats_service_pb.GetTotalMileageRequest,
    user_stats_service_pb.GetTotalMileageResponse,
    (request: user_stats_service_pb.GetTotalMileageRequest) => {
      return request.serializeBinary();
    },
    user_stats_service_pb.GetTotalMileageResponse.deserializeBinary
  );

  getTotalMileage(
    request: user_stats_service_pb.GetTotalMileageRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_stats_service_pb.GetTotalMileageResponse>;

  getTotalMileage(
    request: user_stats_service_pb.GetTotalMileageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetTotalMileageResponse) => void): grpcWeb.ClientReadableStream<user_stats_service_pb.GetTotalMileageResponse>;

  getTotalMileage(
    request: user_stats_service_pb.GetTotalMileageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetTotalMileageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserStatsService/GetTotalMileage',
        request,
        metadata || {},
        this.methodDescriptorGetTotalMileage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserStatsService/GetTotalMileage',
    request,
    metadata || {},
    this.methodDescriptorGetTotalMileage);
  }

  methodDescriptorGetRiderScoreSummary = new grpcWeb.MethodDescriptor(
    '/api.UserStatsService/GetRiderScoreSummary',
    grpcWeb.MethodType.UNARY,
    user_stats_service_pb.GetRiderScoreSummaryRequest,
    user_stats_service_pb.GetRiderScoreSummaryResponse,
    (request: user_stats_service_pb.GetRiderScoreSummaryRequest) => {
      return request.serializeBinary();
    },
    user_stats_service_pb.GetRiderScoreSummaryResponse.deserializeBinary
  );

  getRiderScoreSummary(
    request: user_stats_service_pb.GetRiderScoreSummaryRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_stats_service_pb.GetRiderScoreSummaryResponse>;

  getRiderScoreSummary(
    request: user_stats_service_pb.GetRiderScoreSummaryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetRiderScoreSummaryResponse) => void): grpcWeb.ClientReadableStream<user_stats_service_pb.GetRiderScoreSummaryResponse>;

  getRiderScoreSummary(
    request: user_stats_service_pb.GetRiderScoreSummaryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetRiderScoreSummaryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserStatsService/GetRiderScoreSummary',
        request,
        metadata || {},
        this.methodDescriptorGetRiderScoreSummary,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserStatsService/GetRiderScoreSummary',
    request,
    metadata || {},
    this.methodDescriptorGetRiderScoreSummary);
  }

  methodDescriptorGetTripSummary = new grpcWeb.MethodDescriptor(
    '/api.UserStatsService/GetTripSummary',
    grpcWeb.MethodType.UNARY,
    user_stats_service_pb.GetTripSummaryRequest,
    user_stats_service_pb.GetTripSummaryResponse,
    (request: user_stats_service_pb.GetTripSummaryRequest) => {
      return request.serializeBinary();
    },
    user_stats_service_pb.GetTripSummaryResponse.deserializeBinary
  );

  getTripSummary(
    request: user_stats_service_pb.GetTripSummaryRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_stats_service_pb.GetTripSummaryResponse>;

  getTripSummary(
    request: user_stats_service_pb.GetTripSummaryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetTripSummaryResponse) => void): grpcWeb.ClientReadableStream<user_stats_service_pb.GetTripSummaryResponse>;

  getTripSummary(
    request: user_stats_service_pb.GetTripSummaryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetTripSummaryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserStatsService/GetTripSummary',
        request,
        metadata || {},
        this.methodDescriptorGetTripSummary,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserStatsService/GetTripSummary',
    request,
    metadata || {},
    this.methodDescriptorGetTripSummary);
  }

  methodDescriptorGetRiderScoreGroups = new grpcWeb.MethodDescriptor(
    '/api.UserStatsService/GetRiderScoreGroups',
    grpcWeb.MethodType.UNARY,
    user_stats_service_pb.GetRiderScoreGroupsRequest,
    user_stats_service_pb.GetRiderScoreGroupsResponse,
    (request: user_stats_service_pb.GetRiderScoreGroupsRequest) => {
      return request.serializeBinary();
    },
    user_stats_service_pb.GetRiderScoreGroupsResponse.deserializeBinary
  );

  getRiderScoreGroups(
    request: user_stats_service_pb.GetRiderScoreGroupsRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_stats_service_pb.GetRiderScoreGroupsResponse>;

  getRiderScoreGroups(
    request: user_stats_service_pb.GetRiderScoreGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetRiderScoreGroupsResponse) => void): grpcWeb.ClientReadableStream<user_stats_service_pb.GetRiderScoreGroupsResponse>;

  getRiderScoreGroups(
    request: user_stats_service_pb.GetRiderScoreGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetRiderScoreGroupsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserStatsService/GetRiderScoreGroups',
        request,
        metadata || {},
        this.methodDescriptorGetRiderScoreGroups,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserStatsService/GetRiderScoreGroups',
    request,
    metadata || {},
    this.methodDescriptorGetRiderScoreGroups);
  }

  methodDescriptorGetRiderScoreListByAvgScore = new grpcWeb.MethodDescriptor(
    '/api.UserStatsService/GetRiderScoreListByAvgScore',
    grpcWeb.MethodType.UNARY,
    user_stats_service_pb.GetRiderScoreListByAvgScoreRequest,
    user_stats_service_pb.GetRiderScoreListByAvgScoreResponse,
    (request: user_stats_service_pb.GetRiderScoreListByAvgScoreRequest) => {
      return request.serializeBinary();
    },
    user_stats_service_pb.GetRiderScoreListByAvgScoreResponse.deserializeBinary
  );

  getRiderScoreListByAvgScore(
    request: user_stats_service_pb.GetRiderScoreListByAvgScoreRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_stats_service_pb.GetRiderScoreListByAvgScoreResponse>;

  getRiderScoreListByAvgScore(
    request: user_stats_service_pb.GetRiderScoreListByAvgScoreRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetRiderScoreListByAvgScoreResponse) => void): grpcWeb.ClientReadableStream<user_stats_service_pb.GetRiderScoreListByAvgScoreResponse>;

  getRiderScoreListByAvgScore(
    request: user_stats_service_pb.GetRiderScoreListByAvgScoreRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_stats_service_pb.GetRiderScoreListByAvgScoreResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserStatsService/GetRiderScoreListByAvgScore',
        request,
        metadata || {},
        this.methodDescriptorGetRiderScoreListByAvgScore,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserStatsService/GetRiderScoreListByAvgScore',
    request,
    metadata || {},
    this.methodDescriptorGetRiderScoreListByAvgScore);
  }

}

