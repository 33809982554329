import { Table } from "components/base";
import { TableProps } from "antd/lib/table";
import { useState } from "react";
import { DatePicker, Input } from "antd";
import type { Moment } from "moment";
import { PrinterOutlined } from "@ant-design/icons";
import { searchIcon } from "svgs";
import moment from "moment";
import TripsHeader from "components/tab-headers/trips-header";

const columns: TableProps<any>["columns"] = [
  { title: "Vehicle ID", dataIndex: "vehicle_id" },
  { title: "Rider Name", dataIndex: "rider_name" },
  { title: "Trip Start", dataIndex: "start_time" },
  { title: "Trip End", dataIndex: "end_time" },
  { title: "Distance (KMs)", dataIndex: "kms", align: "center" },
  { title: "Ride Score", dataIndex: "rider_score", align: "right" },
];

const data = [
  { key: 0, kms: "1124", rider_score: "02", start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am", vehicle_id: "ZUM2677", rider_name: "John Doe", },
  { key: 1, kms: "2214", rider_score: "03",  start_time:"10-02-2025 8:32 am",end_time:"10-02-2025 10:42 am",vehicle_id: "ZUM34829", rider_name: "Ahmed Ali", },
  { key: 2, kms: "2344", rider_score: "05",  start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",vehicle_id: "ZUM27825", rider_name: "John Doe", },
  {
    key: 3,
    kms: "3332",
    rider_score: "05",
    start_time:"10-02-2025 8:32 am",end_time:"10-02-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "Hassan Ali",
  },
  {
    key: 4,
    kms: "3332",
    rider_score: "02",
    start_time:"10-02-2025 8:32 am",end_time:"10-02-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "Haris Ali",
  },
  {
    key: 5,
    kms: "3332",
    rider_score: "02",
    start_time:"10-02-2025 8:32 am",end_time:"10-02-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "Haris Ali",
  },
  {
    key: 6,
    kms: "3332",
    rider_score: "02",
    start_time:"10-02-2025 8:32 am",end_time:"10-02-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "Waqar Ali",
  },
  {
    key: 7,
    kms: "3332",
    rider_score: "03",
    start_time:"10-02-2025 8:32 am",end_time:"10-02-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "Tariq Ali",
  },
  {
    key: 8,
    kms: "3332",
    rider_score: "03",
    start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "John Doe",
  },
  {
    key: 9,
    kms: "3332",
    rider_score: "02",
    start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "John Doe",
  },
  {
    key: 10,
    kms: "3332",
    rider_score: "10",
    start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "John Doe",
  },
  {
    key: 11,
    kms: "3332",
    rider_score: "08",
    start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "John Doe",
  },
  {
    key: 12,
    kms: "3332",
    rider_score: "09",
    start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "John Doe",
  },
  {
    key: 13,
    kms: "3332",
    rider_score: "07",
    start_time:"12-01-2025 8:32 am",end_time:"12-01-2025 10:42 am",
    vehicle_id: "ZUM64564",
    rider_name: "John Doe",
  },
];

const csvColumns = [
  { header: "Vehicle ID", key: "vehicle_id" },
  { header: "Rider Name", key: "rider_name" },
  { header: "Start Trip", key: "start_time" },
  { header: "End Trip", key: "end_time" },
  { header: "KMs", key: "kms" },
  { header: "Rider Score", key: "rider_score" },
];

const convertToCSV = (data: any[]) => {
  if (!data.length) return "";
  const headers = csvColumns.map((col) => col.header).join(",");
  const csvRows = [headers];

  data.forEach((row) => {
    const values = csvColumns.map((col) => {
      let value = row[col.key] || "";
      if (col.key === "start_time" || col.key === "end_time") {
        value = `="${value}"`;
      } else {
        value = `"${value}"`;
      }
      return value;
    });
    csvRows.push(values.join(","));
  });

  return csvRows.join("\n");
};

export default function TripsTable() {
  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
  const [searchText, setSearchText] = useState("");

  const filteredData = data.filter((trip) => {
    const matchesSearchText = trip.rider_name.toLowerCase().includes(searchText.toLowerCase());

    const startTimeDate = moment(trip.start_time, "DD-MM-YYYY h:mm a");

    const matchesDate = selectedDate
      ? startTimeDate.isSame(selectedDate, "day") // Compare only the date part (ignoring time)
      : true;

    return matchesSearchText && matchesDate;
  });

  const handleDateChange = (date: Moment | null, dateString: string) => {
    if (date) {
      setSelectedDate(date);
      console.log("Formatted Selected Date:", date.format("DD-MM-YYYY"));
    } else {
      setSelectedDate(null);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleDownloadCsv = () => {
    const csvString = convertToCSV(filteredData);
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "SummaryOfTrips.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <TripsHeader
        selectedDate={selectedDate}
        onDateChange={handleDateChange}
        searchText={searchText}
        onSearchChange={handleSearchChange}
        onDownloadCsv={handleDownloadCsv}
      />
      <Table
        customClasses="--dashboard-table"
        dataSource={filteredData}
        columns={columns}
        pagination={false}
        scroll={{ y: 440, x: 600 }}
      />
    </>
  );
}