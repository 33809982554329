import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import { Table, TablePagination } from "components/base";
import { ROUTES } from "utils/constants";
import { useHistory } from "react-router-dom";
import {
  UpdateVehicle,
  functionalStatusCell,
  renderAssignees,
  renderUtilizationStatus, functionalStatusOnOffCell, serviceStatusCell,
} from "./cell-renders";
import { parseVehiclesTable } from "utils/parsers";
import {
  getClients,
  initializeVehicles,
  setBulkActionsVehicles,
  setDynamicFilters,
  setClientDynamicFilters,
  setModels, setSelectedVehicles,
  setVehiclesSelectedPage,
  setSelectedVehicle,
} from "store/actions";
import { TableProps } from "antd/lib/table";
import useDebounce from "utils/hooks";
import { useInterval } from "utils/hooks";
import { POLLING_MSECS } from "utils/constants";
import {Vehicle} from "../../../types";


const getColumns: (userType: number) => TableProps<any>["columns"] = (
  userType
 
) => {
  const clients = {
    title: "Client",
    dataIndex: "partner",
    width: 100,
    ellipsis: true,
  
  };
  const columns: TableProps<any>["columns"] = [
    {
      title: "Vehicle ID",
      dataIndex: "vehicle_id",
      width: 100,
      ellipsis: true,
   
    },
    {
      title: "Billing",
      dataIndex: "is_billing_active",
      width: 130,
      ellipsis: true,
    },
    {
      title: "Model",
      dataIndex: "model",
      width: 130,
      ellipsis: true,
    
    },
    {
      title: "Functional Status",
      dataIndex: "vehicle_functional_status",
      render: functionalStatusOnOffCell,
      width: 150,
      ellipsis: true,
      
    },
    // {
    //   title: "Utilization Status",
    //   dataIndex: "utilization_status",
    //   width: 140,
    //   ellipsis: true,
    //   render: renderUtilizationStatus,
    // },
    {
      title: "Assignee",
      dataIndex: "driver_name",
      width: 100,
      ellipsis: true,
      render: renderAssignees,
    },
    {
      title: "Device ID",
      dataIndex: "iot_id",
      width: 130,
      ellipsis: true,
   
    },
    {
      title: "Service Status",
      dataIndex: "vehicle_service_status",
      width: 140,
      ellipsis: true,
      render: serviceStatusCell,
    },
    {
      title: "Zone",
      dataIndex: "zone",
      width: 140,
      ellipsis: true,
    
    },
    {
      title: "Battery",
      dataIndex: "battery",
      width: 90,
      ellipsis: true,
    
    },
    {
      title: "Checked Out",
      dataIndex: "checkout_by_name",
      width: 130,
      ellipsis: true,
     
    },
    {
      title: "VIN",
      dataIndex: "vin",
      width: 130,
      ellipsis: true,
    },
    {
      title: "",
      dataIndex: "action",
      render: UpdateVehicle,
      align: "left",
      width: 56,
      className: "action-menu open-link --user",
    },

  ];
  const colWithClients = [...columns];
  colWithClients.splice(1, 0, clients)
  if (userType == 1) {
    return colWithClients
  } else if (userType == 2) {
    return colWithClients.filter((col => col.title !== "Assignee"))
  }
  return columns.filter(
      (column) =>
          column.title !== "Battery" && column.title !== "Device ID" && column.title !== "VIN" && column.title !== "Billing"
  );
};

export default function TableView() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    vehicles: {
      vehicles,
      page,
      search,
      models,
      clients,
      filters: { updated },
    },
    common: { userType = 1 },
  } = useSelector<AppState, AppState>((state) => state);
  const { selectedPage, totalPages } = page;
  const data = useMemo(() => parseVehiclesTable(vehicles), [vehicles]);
  const [selectedRows, setSelectedRows] = useState([]);
 

  const onBack = useCallback(() => {
    if (selectedPage > 1) {
      dispatch(setVehiclesSelectedPage(selectedPage - 1));
    }
  }, [dispatch, selectedPage]);

  const onNext = useCallback(() => {
    if (selectedPage < totalPages) {
      dispatch(setVehiclesSelectedPage(selectedPage + 1));
    }
  }, [dispatch, selectedPage, totalPages]);

  const resetCallback = useCallback(() => {
    dispatch(setVehiclesSelectedPage(1));
  }, [dispatch]);

  const debounceSearchString = useDebounce(search, 1000);

  useEffect(() => {
    dispatch(setModels());
    dispatch(getClients());
  }, [dispatch]);


  useEffect(() => {
    dispatch(setDynamicFilters(models));
    dispatch(setClientDynamicFilters(clients));
  }, [models,clients, dispatch]);


  useInterval(() => {
    dispatch(initializeVehicles(selectedPage, updated, false, false));
  }, POLLING_MSECS);

  useEffect(() => {
    dispatch(initializeVehicles(selectedPage, updated));
  }, [dispatch, selectedPage, updated, debounceSearchString]);


  const onChangeSelection = useCallback(
      (value) => {
        setSelectedRows(value);

        if (value.length !== 0) {
          dispatch(setBulkActionsVehicles(true));
          const selectedItems: (Vehicle | null)[] = value.map((index: number, key: any) => {
            if (index >= 0 && data && index < data.length) {
              return data[index].raw;
            } else {
              return null;
            }
          });
          dispatch(setSelectedVehicles(selectedItems))

        } else {
          dispatch(setBulkActionsVehicles(false));
        }
      },

      [data,dispatch]
  );


  const handleRowClick = (vehicle: any) => {
    dispatch(setSelectedVehicle(vehicle));
    history.push(ROUTES.vehicle_detail);
  };

  return (
    <>
      <Table
        columns={getColumns(userType)}
        dataSource={data}
        onRow={(record) => {
          return {
            onClick: () => handleRowClick(record.raw), // Handle row click
          };
        }}
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: onChangeSelection,
        }}
        scroll={{ y: "70vh" }}
        pagination={false}
      />
      <TablePagination
        page={page}
        onBack={onBack}
        onNext={onNext}
        resetCallback={resetCallback}
      />
    </>
  );
}
