/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.2
// source: issue_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as issue_service_pb from './issue_service_pb';


export class IssueServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetMyIssues = new grpcWeb.MethodDescriptor(
    '/api.IssueService/GetMyIssues',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.GetMyIssuesRequest,
    issue_service_pb.GetMyIssuesResponse,
    (request: issue_service_pb.GetMyIssuesRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.GetMyIssuesResponse.deserializeBinary
  );

  getMyIssues(
    request: issue_service_pb.GetMyIssuesRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.GetMyIssuesResponse>;

  getMyIssues(
    request: issue_service_pb.GetMyIssuesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.GetMyIssuesResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.GetMyIssuesResponse>;

  getMyIssues(
    request: issue_service_pb.GetMyIssuesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.GetMyIssuesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueService/GetMyIssues',
        request,
        metadata || {},
        this.methodDescriptorGetMyIssues,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueService/GetMyIssues',
    request,
    metadata || {},
    this.methodDescriptorGetMyIssues);
  }

  methodDescriptorGetMyCreatedIssues = new grpcWeb.MethodDescriptor(
    '/api.IssueService/GetMyCreatedIssues',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.GetMyIssuesRequest,
    issue_service_pb.GetMyCreatedIssuesResponse,
    (request: issue_service_pb.GetMyIssuesRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.GetMyCreatedIssuesResponse.deserializeBinary
  );

  getMyCreatedIssues(
    request: issue_service_pb.GetMyIssuesRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.GetMyCreatedIssuesResponse>;

  getMyCreatedIssues(
    request: issue_service_pb.GetMyIssuesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.GetMyCreatedIssuesResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.GetMyCreatedIssuesResponse>;

  getMyCreatedIssues(
    request: issue_service_pb.GetMyIssuesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.GetMyCreatedIssuesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueService/GetMyCreatedIssues',
        request,
        metadata || {},
        this.methodDescriptorGetMyCreatedIssues,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueService/GetMyCreatedIssues',
    request,
    metadata || {},
    this.methodDescriptorGetMyCreatedIssues);
  }

  methodDescriptorAddIssue = new grpcWeb.MethodDescriptor(
    '/api.IssueService/AddIssue',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.AddIssueRequest,
    issue_service_pb.AddIssueResponse,
    (request: issue_service_pb.AddIssueRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.AddIssueResponse.deserializeBinary
  );

  addIssue(
    request: issue_service_pb.AddIssueRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.AddIssueResponse>;

  addIssue(
    request: issue_service_pb.AddIssueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.AddIssueResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.AddIssueResponse>;

  addIssue(
    request: issue_service_pb.AddIssueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.AddIssueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueService/AddIssue',
        request,
        metadata || {},
        this.methodDescriptorAddIssue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueService/AddIssue',
    request,
    metadata || {},
    this.methodDescriptorAddIssue);
  }

  methodDescriptorUpdateIssue = new grpcWeb.MethodDescriptor(
    '/api.IssueService/UpdateIssue',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.UpdateIssueRequest,
    issue_service_pb.UpdateIssueResponse,
    (request: issue_service_pb.UpdateIssueRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.UpdateIssueResponse.deserializeBinary
  );

  updateIssue(
    request: issue_service_pb.UpdateIssueRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.UpdateIssueResponse>;

  updateIssue(
    request: issue_service_pb.UpdateIssueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.UpdateIssueResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.UpdateIssueResponse>;

  updateIssue(
    request: issue_service_pb.UpdateIssueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.UpdateIssueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueService/UpdateIssue',
        request,
        metadata || {},
        this.methodDescriptorUpdateIssue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueService/UpdateIssue',
    request,
    metadata || {},
    this.methodDescriptorUpdateIssue);
  }

}

export class IssueManagementServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddIssue = new grpcWeb.MethodDescriptor(
    '/api.IssueManagementService/AddIssue',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.AddIssueManagementRequest,
    issue_service_pb.AddIssueManagementResponse,
    (request: issue_service_pb.AddIssueManagementRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.AddIssueManagementResponse.deserializeBinary
  );

  addIssue(
    request: issue_service_pb.AddIssueManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.AddIssueManagementResponse>;

  addIssue(
    request: issue_service_pb.AddIssueManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.AddIssueManagementResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.AddIssueManagementResponse>;

  addIssue(
    request: issue_service_pb.AddIssueManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.AddIssueManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueManagementService/AddIssue',
        request,
        metadata || {},
        this.methodDescriptorAddIssue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueManagementService/AddIssue',
    request,
    metadata || {},
    this.methodDescriptorAddIssue);
  }

  methodDescriptorListIssues = new grpcWeb.MethodDescriptor(
    '/api.IssueManagementService/ListIssues',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.ListIssuesManagementRequest,
    issue_service_pb.ListIssuesManagementResponse,
    (request: issue_service_pb.ListIssuesManagementRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.ListIssuesManagementResponse.deserializeBinary
  );

  listIssues(
    request: issue_service_pb.ListIssuesManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.ListIssuesManagementResponse>;

  listIssues(
    request: issue_service_pb.ListIssuesManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.ListIssuesManagementResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.ListIssuesManagementResponse>;

  listIssues(
    request: issue_service_pb.ListIssuesManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.ListIssuesManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueManagementService/ListIssues',
        request,
        metadata || {},
        this.methodDescriptorListIssues,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueManagementService/ListIssues',
    request,
    metadata || {},
    this.methodDescriptorListIssues);
  }

  methodDescriptorDeleteIssues = new grpcWeb.MethodDescriptor(
    '/api.IssueManagementService/DeleteIssues',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.DeleteIssueManagementRequest,
    issue_service_pb.DeleteIssueManagementResponse,
    (request: issue_service_pb.DeleteIssueManagementRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.DeleteIssueManagementResponse.deserializeBinary
  );

  deleteIssues(
    request: issue_service_pb.DeleteIssueManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.DeleteIssueManagementResponse>;

  deleteIssues(
    request: issue_service_pb.DeleteIssueManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.DeleteIssueManagementResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.DeleteIssueManagementResponse>;

  deleteIssues(
    request: issue_service_pb.DeleteIssueManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.DeleteIssueManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueManagementService/DeleteIssues',
        request,
        metadata || {},
        this.methodDescriptorDeleteIssues,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueManagementService/DeleteIssues',
    request,
    metadata || {},
    this.methodDescriptorDeleteIssues);
  }

  methodDescriptorUndoDeleteIssues = new grpcWeb.MethodDescriptor(
    '/api.IssueManagementService/UndoDeleteIssues',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.UndoDeleteIssueManagementRequest,
    issue_service_pb.UndoDeleteIssueManagementResponse,
    (request: issue_service_pb.UndoDeleteIssueManagementRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.UndoDeleteIssueManagementResponse.deserializeBinary
  );

  undoDeleteIssues(
    request: issue_service_pb.UndoDeleteIssueManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.UndoDeleteIssueManagementResponse>;

  undoDeleteIssues(
    request: issue_service_pb.UndoDeleteIssueManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.UndoDeleteIssueManagementResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.UndoDeleteIssueManagementResponse>;

  undoDeleteIssues(
    request: issue_service_pb.UndoDeleteIssueManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.UndoDeleteIssueManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueManagementService/UndoDeleteIssues',
        request,
        metadata || {},
        this.methodDescriptorUndoDeleteIssues,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueManagementService/UndoDeleteIssues',
    request,
    metadata || {},
    this.methodDescriptorUndoDeleteIssues);
  }

  methodDescriptorUpdateIssue = new grpcWeb.MethodDescriptor(
    '/api.IssueManagementService/UpdateIssue',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.UpdateIssueManagmentRequest,
    issue_service_pb.UpdateIssueManagmentResponse,
    (request: issue_service_pb.UpdateIssueManagmentRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.UpdateIssueManagmentResponse.deserializeBinary
  );

  updateIssue(
    request: issue_service_pb.UpdateIssueManagmentRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.UpdateIssueManagmentResponse>;

  updateIssue(
    request: issue_service_pb.UpdateIssueManagmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.UpdateIssueManagmentResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.UpdateIssueManagmentResponse>;

  updateIssue(
    request: issue_service_pb.UpdateIssueManagmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.UpdateIssueManagmentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueManagementService/UpdateIssue',
        request,
        metadata || {},
        this.methodDescriptorUpdateIssue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueManagementService/UpdateIssue',
    request,
    metadata || {},
    this.methodDescriptorUpdateIssue);
  }

  methodDescriptorListConsignees = new grpcWeb.MethodDescriptor(
    '/api.IssueManagementService/ListConsignees',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.ListUsersConsigneeManagementRequest,
    issue_service_pb.ListUsersConsigneeManagementResponse,
    (request: issue_service_pb.ListUsersConsigneeManagementRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.ListUsersConsigneeManagementResponse.deserializeBinary
  );

  listConsignees(
    request: issue_service_pb.ListUsersConsigneeManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.ListUsersConsigneeManagementResponse>;

  listConsignees(
    request: issue_service_pb.ListUsersConsigneeManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.ListUsersConsigneeManagementResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.ListUsersConsigneeManagementResponse>;

  listConsignees(
    request: issue_service_pb.ListUsersConsigneeManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.ListUsersConsigneeManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueManagementService/ListConsignees',
        request,
        metadata || {},
        this.methodDescriptorListConsignees,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueManagementService/ListConsignees',
    request,
    metadata || {},
    this.methodDescriptorListConsignees);
  }

  methodDescriptorListUnresolved = new grpcWeb.MethodDescriptor(
    '/api.IssueManagementService/ListUnresolved',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.ListUnresolvedDashboardManagementRequest,
    issue_service_pb.ListUnresolvedDashboardManagementResponse,
    (request: issue_service_pb.ListUnresolvedDashboardManagementRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.ListUnresolvedDashboardManagementResponse.deserializeBinary
  );

  listUnresolved(
    request: issue_service_pb.ListUnresolvedDashboardManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.ListUnresolvedDashboardManagementResponse>;

  listUnresolved(
    request: issue_service_pb.ListUnresolvedDashboardManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.ListUnresolvedDashboardManagementResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.ListUnresolvedDashboardManagementResponse>;

  listUnresolved(
    request: issue_service_pb.ListUnresolvedDashboardManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.ListUnresolvedDashboardManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueManagementService/ListUnresolved',
        request,
        metadata || {},
        this.methodDescriptorListUnresolved,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueManagementService/ListUnresolved',
    request,
    metadata || {},
    this.methodDescriptorListUnresolved);
  }

  methodDescriptorCountIssuesByStatus = new grpcWeb.MethodDescriptor(
    '/api.IssueManagementService/CountIssuesByStatus',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.CountIssuesByStatusManagementRequest,
    issue_service_pb.CountIssuesByStatusManagementResponse,
    (request: issue_service_pb.CountIssuesByStatusManagementRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.CountIssuesByStatusManagementResponse.deserializeBinary
  );

  countIssuesByStatus(
    request: issue_service_pb.CountIssuesByStatusManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.CountIssuesByStatusManagementResponse>;

  countIssuesByStatus(
    request: issue_service_pb.CountIssuesByStatusManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.CountIssuesByStatusManagementResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.CountIssuesByStatusManagementResponse>;

  countIssuesByStatus(
    request: issue_service_pb.CountIssuesByStatusManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.CountIssuesByStatusManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueManagementService/CountIssuesByStatus',
        request,
        metadata || {},
        this.methodDescriptorCountIssuesByStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueManagementService/CountIssuesByStatus',
    request,
    metadata || {},
    this.methodDescriptorCountIssuesByStatus);
  }

  methodDescriptorCountIssuesWeekly = new grpcWeb.MethodDescriptor(
    '/api.IssueManagementService/CountIssuesWeekly',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.CountIssuesWeeklyManagementRequest,
    issue_service_pb.CountIssuesWeeklyManagementResponse,
    (request: issue_service_pb.CountIssuesWeeklyManagementRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.CountIssuesWeeklyManagementResponse.deserializeBinary
  );

  countIssuesWeekly(
    request: issue_service_pb.CountIssuesWeeklyManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.CountIssuesWeeklyManagementResponse>;

  countIssuesWeekly(
    request: issue_service_pb.CountIssuesWeeklyManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.CountIssuesWeeklyManagementResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.CountIssuesWeeklyManagementResponse>;

  countIssuesWeekly(
    request: issue_service_pb.CountIssuesWeeklyManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.CountIssuesWeeklyManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueManagementService/CountIssuesWeekly',
        request,
        metadata || {},
        this.methodDescriptorCountIssuesWeekly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueManagementService/CountIssuesWeekly',
    request,
    metadata || {},
    this.methodDescriptorCountIssuesWeekly);
  }

  methodDescriptorDashboardCounts = new grpcWeb.MethodDescriptor(
    '/api.IssueManagementService/DashboardCounts',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.CountIssuesByTypeManagementRequest,
    issue_service_pb.CountIssuesByTypeManagementResponse,
    (request: issue_service_pb.CountIssuesByTypeManagementRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.CountIssuesByTypeManagementResponse.deserializeBinary
  );

  dashboardCounts(
    request: issue_service_pb.CountIssuesByTypeManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.CountIssuesByTypeManagementResponse>;

  dashboardCounts(
    request: issue_service_pb.CountIssuesByTypeManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.CountIssuesByTypeManagementResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.CountIssuesByTypeManagementResponse>;

  dashboardCounts(
    request: issue_service_pb.CountIssuesByTypeManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.CountIssuesByTypeManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueManagementService/DashboardCounts',
        request,
        metadata || {},
        this.methodDescriptorDashboardCounts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueManagementService/DashboardCounts',
    request,
    metadata || {},
    this.methodDescriptorDashboardCounts);
  }

  methodDescriptorGetTotalIssuesCountWithType = new grpcWeb.MethodDescriptor(
    '/api.IssueManagementService/GetTotalIssuesCountWithType',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.GetTotalIssuesCountWithTypeRequest,
    issue_service_pb.GetTotalIssuesCountWithTypeResponse,
    (request: issue_service_pb.GetTotalIssuesCountWithTypeRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.GetTotalIssuesCountWithTypeResponse.deserializeBinary
  );

  getTotalIssuesCountWithType(
    request: issue_service_pb.GetTotalIssuesCountWithTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.GetTotalIssuesCountWithTypeResponse>;

  getTotalIssuesCountWithType(
    request: issue_service_pb.GetTotalIssuesCountWithTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.GetTotalIssuesCountWithTypeResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.GetTotalIssuesCountWithTypeResponse>;

  getTotalIssuesCountWithType(
    request: issue_service_pb.GetTotalIssuesCountWithTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.GetTotalIssuesCountWithTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueManagementService/GetTotalIssuesCountWithType',
        request,
        metadata || {},
        this.methodDescriptorGetTotalIssuesCountWithType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueManagementService/GetTotalIssuesCountWithType',
    request,
    metadata || {},
    this.methodDescriptorGetTotalIssuesCountWithType);
  }

}

export class IssueAdminServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListIssues = new grpcWeb.MethodDescriptor(
    '/api.IssueAdminService/ListIssues',
    grpcWeb.MethodType.UNARY,
    issue_service_pb.ListIssuesAdminRequest,
    issue_service_pb.ListIssuesAdminResponse,
    (request: issue_service_pb.ListIssuesAdminRequest) => {
      return request.serializeBinary();
    },
    issue_service_pb.ListIssuesAdminResponse.deserializeBinary
  );

  listIssues(
    request: issue_service_pb.ListIssuesAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<issue_service_pb.ListIssuesAdminResponse>;

  listIssues(
    request: issue_service_pb.ListIssuesAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: issue_service_pb.ListIssuesAdminResponse) => void): grpcWeb.ClientReadableStream<issue_service_pb.ListIssuesAdminResponse>;

  listIssues(
    request: issue_service_pb.ListIssuesAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: issue_service_pb.ListIssuesAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.IssueAdminService/ListIssues',
        request,
        metadata || {},
        this.methodDescriptorListIssues,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.IssueAdminService/ListIssues',
    request,
    metadata || {},
    this.methodDescriptorListIssues);
  }

}

