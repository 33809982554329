import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {useCallback, useEffect, useMemo} from "react";
import {parseOrganizationClients} from "../../../utils/parsers";
import {initializeClients} from "../../../store/actions";
import {Table} from "../../base";
import {TableProps} from "antd/lib/table";
import {ActionMenu} from "./cell-renders";

const allColumns: TableProps<any>["columns"] = [
    {
        title: "Name",
        dataIndex: "name",
        width: 170,
    },
    {
        title: "Address",
        dataIndex: "address",
        width: 130,
    },
    {
        title: "City",
        dataIndex: "city",
        width: 130,
    },
    {
        title: "Province",
        dataIndex: "state",
        width: 130,
    },
    {
        title: "Country",
        dataIndex: "country",
        width: 130,
    },
    // {
    //   title: "Number of Vehicles",
    //   dataIndex: "vehicle_count",
    //   width: 170,
    // },
    // {
    //   title: "Onboarding Date",
    //   dataIndex: "onboarding_date",
    //   width: 130,
    // },
    // {
    //   title: "Organization Type",
    //   dataIndex: "organization_type",
    //   width: 130,
    // },
    // {
    //   title: "Organization Admin",
    //   dataIndex: "client_admin",
    // },
    {
        title: "",
        dataIndex: "action",
        render: ActionMenu,
        align: "right",
        // width: 56,
        className: "action-menu open-link --issues",
    },

];
export default function B2CTableView() {
    const dispatch = useDispatch();
    const {
        organizations,
        page,
        search,
        filters: { listType },
        selectedOrganizationsRows,
    } = useSelector<AppState, AppState["organizations"]>((state) => state.organizations);
    const { selectedPage, totalPages } = page;
    const data = useMemo(
        () => parseOrganizationClients(organizations),
        [organizations, listType, search]
    );
    useEffect(() => {
        dispatch(initializeClients(2));
    }, [dispatch]);
    console.log("data of organization page : ", data)
    const onChangeSelection = useCallback(
        (value) => {
            console.log("values : ", value)
        },
        []
    );
    return (
        <>
            <Table
                columns={allColumns}
                dataSource={data}
                pagination={false}
                // rowSelection={{
                //   selectedRowKeys: selectedOrganizationsRows,
                //   onChange: onChangeSelection,
                // }}
                scroll={{ y: "70vh" }}
            />
        </>
    );
}