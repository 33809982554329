import { useEffect, useState } from "react";
import styled from "styled-components";
import { BarChart } from "@k2/rv-viz";
import { Modal } from "antd";
import RiderScoreTable from "./rider-score"; // Adjust the import path as needed

const data = [
  {
    data: [
      {
        color: "rgba(245, 116, 0, 0.2)",
        labelColor: "#777777",
        x: 0, // Updated dynamically
        y: "Bad",
      },
      {
        color: "rgba(114, 48, 200, 0.2)",
        labelColor: "#777777",
        x: 0, // Updated dynamically
        y: "Average",
      },
   
      {
        color: "rgba(10, 189, 105, 0.34)",
        labelColor: "#777777",
        x: 0, // Updated dynamically
        y: "Good",
      },
    ],
    name: "Series 1",
  },
];

const BarChartStyled = styled(BarChart)<{
  bar1Offset: number;
  bar2Offset: number;
  bar3Offset: number;
  borderHeight: number;
}>`
  &.custom-bar-chart {
    .k2--horizontal-bar-series {
      > rect {
        stroke-width: 4px;
        stroke-dasharray: calc(${(props) => props.borderHeight} - 1) 3000;

        :nth-last-of-type(3) {
          stroke-dashoffset: calc(-${(props) => props.bar1Offset} - 1);
          stroke: rgba(245, 116, 0) !important;
        }
        :nth-last-of-type(2) {
          stroke-dashoffset: calc(-${(props) => props.bar2Offset} - 1);
          stroke: rgba(114, 48, 200) !important;
        }
        :nth-last-of-type(1) {
          stroke-dashoffset: calc(-${(props) => props.bar3Offset} - 1);
          stroke: rgba(69, 128, 217) !important;
        }
      }
    }
  }
`;

export default function RiderScoreChart() {
  const [state, setState] = useState({
    offset1: 0,
    offset2: 0,
    offset3: 0,
    borderHeight: 49,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<"Good" | "Average" | "Bad" | null>(null);

  // Sample data for riders
  const ridersData = [
    { key: 0, rider_id: "Ahmed", rides: "12", distance: "323", range: "08", category: "Good" },
    { key: 1, rider_id: "Imran", rides: "10", distance: "483", range: "06", category: "Average" },
    { key: 2, rider_id: "Zaid", rides: "16", distance: "723", range: "07", category: "Bad" },
    { key: 3, rider_id: "Ali", rides: "14", distance: "400", range: "09", category: "Good" },
    { key: 4, rider_id: "Bilal", rides: "8", distance: "200", range: "05", category: "Bad" },
    { key: 5, rider_id: "Kamran", rides: "20", distance: "1000", range: "10", category: "Good" },
    { key: 6, rider_id: "Javed", rides: "18", distance: "900", range: "09", category: "Average" },
    { key: 7, rider_id: "Farhan", rides: "6", distance: "150", range: "04", category: "Bad" },
    { key: 8, rider_id: "Rizwan", rides: "4", distance: "100", range: "03", category: "Good" },
    { key: 9, rider_id: "Tariq", rides: "2", distance: "50", range: "02", category: "Average" },
    { key: 10, rider_id: "Nadeem", rides: "1", distance: "25", range: "01", category: "Bad" },
    { key: 11, rider_id: "Sajid", rides: "3", distance: "75", range: "02", category: "Good" },
    { key: 12, rider_id: "Asad", rides: "5", distance: "125", range: "03", category: "Average" },
    { key: 13, rider_id: "Waqas", rides: "7", distance: "175", range: "04", category: "Bad" },
    { key: 14, rider_id: "Noman", rides: "9", distance: "225", range: "05", category: "Good" },
    { key: 16, rider_id: "Kashif", rides: "13", distance: "325", range: "07", category: "Bad" },
  ];

  // Calculate the count of riders in each category (Good, Average, Bad)
  const categoryCounts = {
    Good: ridersData.filter((rider) => rider.category === "Good").length,
    Average: ridersData.filter((rider) => rider.category === "Average").length,
    Bad: ridersData.filter((rider) => rider.category === "Bad").length,
  };

  // Update the data object with the count of riders in each category
  data[0].data[0].x = categoryCounts.Bad; 
  data[0].data[1].x = categoryCounts.Average; // Average category
  data[0].data[2].x = categoryCounts.Good; 

  // Filter riders based on the selected category
  const filteredRiders = selectedCategory
    ? ridersData.filter((rider) => rider.category === selectedCategory)
    : ridersData; // Show all data if no category is selected

  useEffect(() => {
    function reportWindowSize() {
      setTimeout(() => {
        const barRects = document.querySelectorAll(
          ".custom-bar-chart .k2--horizontal-bar-series > rect"
        );
        const bar3 = barRects[0]?.getBoundingClientRect().width;
        const borderHeight = barRects[0]?.getBoundingClientRect().height;
        const bar2 = barRects[1]?.getBoundingClientRect().width;
        const bar1 = barRects[2]?.getBoundingClientRect().width;
        setState({
          offset1: bar1,
          offset2: bar2,
          offset3: bar3,
          borderHeight: borderHeight,
        });
      }, 500);
    }
    reportWindowSize();
    window.addEventListener("resize", reportWindowSize);

    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, []);

  // Handle click event on the bars
  const handleBarClick = (datapoint: { y: "Good" | "Average" | "Bad" }) => {
    const category = datapoint.y; // Get the category from the clicked bar
    setSelectedCategory(category);
    setIsModalVisible(true);
  };

  const totalX = data[0].data.reduce((acc, item) => acc + item.x, 0);

  return (
  <>
    <h3 style={{ margin: 0, marginBottom: "10px" }}>Rider's Score</h3>
    <div style={{ height: "180px" }}> 
      <BarChartStyled
        borderHeight={state.borderHeight}
        bar1Offset={state.offset1}
        bar2Offset={state.offset2}
        bar3Offset={state.offset3}
        barWidth={0.95}
        data={data}
       
        horizontalGridLines={false}
        legends={false}
        animate={true}
        tooltip={true}
        xAxis={{
          hideLine: true,
          tickSize: 0,
          tickValues: [],
        }}
        yAxis={{
          hideLine: true,
          tickSize: 0,
        }}
        xyPlot={{
          margin: {
            left: 50,
            top: 0,
            bottom: 30,
            right: 5,
           
          },
        //  xDomain: [0, totalX],
        }}
        className="custom-bar-chart"
        onClick={(event: any) => {
          const category = event?.data?.y;
          if (category) {
            handleBarClick({ y: category });
          }
        }}
      />
    </div>

    <Modal
      title={`Rider's Scores - ${selectedCategory || "All Categories"}`}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
      width={800}
    >
      <RiderScoreTable data={filteredRiders} />
    </Modal>
  </>
);

}
