import React, { useState } from "react";
import { PieChart, PieChartData } from "@k2/rv-viz";
import { Modal, Table } from "antd";
import { pieChartWidget } from "./../../svgs";
import { Select, DatePicker } from "antd"; // Import Ant Design's Select component
import dayjs from "dayjs";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function VehiclesRenderChart() {
  const [selectedFilter, setSelectedFilter] = useState("this month"); // Default filter value
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  // Example data for the table (categoryData)
  type CategoryData = {
    Operational: { vehicle_id: string; issue_type: string; due_date: string }[];
    "In Maintenance": { vehicle_id: string; issue_type: string; due_date: string }[];
    "Marked Broken": { vehicle_id: string; issue_type: string; due_date: string }[];
    Retired: { vehicle_id: string; issue_type: string; due_date: string }[];
  };

  const categoryData = {
    Operational: [
      { vehicle_id: "V001", model: "Model X",  assignee: "John",  zone: "North"},
      { vehicle_id: "V002", model: "Model Y",  assignee: "Alice",  zone: "South" },
    ],
    "In Maintenance": [
      { vehicle_id: "V003", model: "Model Z", assignee: "Bob",  zone: "East" },
    ],
    "Marked Broken": [
      { vehicle_id: "V004", model: "Model A",  assignee: "Charlie",  zone: "West" },
    ],
    Retired: [
      { vehicle_id: "V005", model: "Model B",  assignee: "David",  zone: "Central" },
    ],
  };

  // Dynamically update pie chart data based on categoryData
  const updatedPieData: PieChartData[] = Object.keys(categoryData).map((category) => ({
    label: category,
    value: categoryData[category as keyof CategoryData].length, // Number of vehicles in each category
  }));

  // Handle the Pie Chart click event
  const handlePieClick = (event: { data: PieChartData }) => {
    const category = event.data.label;
    setSelectedCategory(category);
    setIsModalVisible(true);
  };

  const filteredCategoryData = selectedCategory ? categoryData[selectedCategory as keyof CategoryData] : [];

  // Table columns definition
  const columns = [
    {
      title: "Vehicle ID",
      dataIndex: "vehicle_id",
    },
   
    {
      title: "Assignee",
      dataIndex: "assignee",
    },
    {
      title: "Zone",
      dataIndex: "zone",
      align: "center" as "center" | undefined,
    },
  
  ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <h3 style={{ margin: 0 }}>Vehicles Status</h3>
        {/* You can enable the filter section if needed */}
      </div>

      <div className="pie-chart-wrapper">
        <div className="pie-chart-container">
          <PieChart
            legends={false}
            colors={{
              dark: ["#70b857", "#4580d9", "#ed2020", "#ecc600"],
              light: ["#70b857", "#4580d9", "#ed2020", "#ecc600"],
            }}
            data={updatedPieData} // Use dynamically updated pie data with the correct values
            radial={{
              anglePadding: 2,
              innerRadius: 0.9,
            }}
            title={""}
            classes={{
              label: "label-container",
            }}
            onClick={(event) => handlePieClick(event)} // Click event to handle pie chart clicks
            componentStates={{
              blank: <figure className="empty-state-widget">{pieChartWidget}</figure>,
            }}
          />
        </div>
      </div>

      {/* Modal to display category data */}
      <Modal
        title={`Vehicle Status: ${selectedCategory}`}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800} // Adjust width as needed
      >
        <div>
          {filteredCategoryData.length > 0 ? (
            <Table
              dataSource={filteredCategoryData}
              columns={columns}
              pagination={false}
              scroll={{ y: 300 }} // Set the scroll height if needed
            />
          ) : (
            <p>No data available for this category.</p>
          )}
        </div>
      </Modal>
    </div>
  );
}
