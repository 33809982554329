import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Key } from 'react';  // Import type for selected rows
import { AppState } from "store";
import { Table, TablePagination } from "components/base";
import {
  getCategories,
  initializeNotifications,
  setNotificationsSelectedPage,
  setNotificationDynamicFilters,
  setSelectedNotifications,
  setBulkActionsNotifications

} from "store/actions";
import {Notificatons} from "../../../types";
import { TableProps } from "antd/lib/table";
import useDebounce from "utils/hooks";
import { markReadNotifications} from "store/actions";
import {parseNotificationTable} from "../../../utils/parsers/fcm_notification_parser";
const columns: TableProps<any>["columns"] = [
  {
    title: "Category",
    dataIndex: "category",
    width: 100,
    ellipsis: true,
    render: (text: string, record: { is_read: boolean }) => (
      <span style={{ position: 'relative', fontWeight: record.is_read ? 'normal' : 'bold' }}>
        {!record.is_read && (
          <span style={{
            position: 'absolute',
            top: '-5px', // Adjust to move the asterisk to the upper left corner
            left: '-5px', // Adjust to move the asterisk to the upper left corner
            color: 'red' // Customize the color of the asterisk
          }}>
            *
          </span>
        )}
        {text}
      </span>
    ),
  },
  {
    title: "Sub Category",
    dataIndex: "subCategory",
    width: 130,
    ellipsis: true,
    render: (text: string, record: { is_read: boolean }) => (
      <span style={{ fontWeight: record.is_read ? 'normal' : 'bold' }}>
        {text}
      </span>
    ),
  },
  {
    title: "Date & Time",
    dataIndex: "notification_time",
    width: 175,
    ellipsis: true,
    render: (text: string, record: { is_read: boolean }) => (
      <span style={{ fontWeight: record.is_read ? 'normal' : 'bold' }}>
        {text}
      </span>
    ),
  },
  {
    title: "Priority",
    dataIndex: "priority",
    width: 100,
    ellipsis: true,
    render: (text: string, record: { is_read: boolean }) => (
      <span style={{ fontWeight: record.is_read ? 'normal' : 'bold' }}>
        {text}
      </span>
    ),
  },
  {
    title: "Description",
    dataIndex: "body",
    width: 340,
    ellipsis: true,
    align: "left",
    render: (text: string, record: { is_read: boolean }) => (
      <span
        style={{
          fontWeight: record.is_read ? "normal" : "bold",
          display: "inline-block",
          whiteSpace: "normal", // Ensures text wraps
          wordBreak: "break-word", // Handles long words
        }}
      >
        {text.split(" ").slice(0, 8).join(" ")} <br />
        {text.split(" ").slice(8).join(" ")}
      </span>
    ),
  },
  
  // { 
  //   title: "Status",
  //   dataIndex: "is_read",
  //   width: 90,
  //   ellipsis: true,
  //   align: "left",
  //     render: (text: string, record: { is_read: boolean }) => (
  //     <span style={{ fontWeight: record.is_read ? 'normal' : 'bold' }}>
  //       {text}
  //     </span>
  //   ),
  // },
  
];

const mockData = [
    {
       category: "Kiosks",
       sub_category: "Slot Malfunction",
       date_time: "24 Sept,2024 12:08",
       priority: "High",
       description:"Slot 1, KID has mulfunctioned",
       status:"Read"

    },
    {
            category: "Battery",
            sub_category: "Not charging",
            date_time: new Date().toString(),
            priority: "Medium",
            description:"B34: Battery not charging",
            status:"Read"
     
         },
         {
            category: "Vehicles",
            sub_category: "Zone Voilation",
            date_time: new Date().toString(),
            priority: "Low",
            description:"Vehicle P02: voilate zone",
            status:"Read"
     
         },
];

interface TableViewProps {
  selectedRows: Key[];  // Type for selected rows (React.Key array)
  onChangeSelection: (selectedKeys: Key[]) => void;  // Function type for selection change
}
export default function TableView({ selectedRows, onChangeSelection }: TableViewProps) {
  const dispatch = useDispatch();
  const {
    notificationCenter: {
      notificationsCenter,
      page,
      search,
      categories,
      subCategories,
      filters: { updated },
    },
  
  } = useSelector<AppState, AppState>((state) => state);
  const data = useMemo(
    () => parseNotificationTable(notificationsCenter),
    [notificationsCenter]
);
  const { selectedPage, totalPages } = page;


  const onBack = useCallback(() => {
    if (selectedPage > 1) {
      dispatch(setNotificationsSelectedPage(selectedPage - 1));
    }
  }, [dispatch, selectedPage]);

  const onNext = useCallback(() => {
    if (selectedPage < totalPages) {
      dispatch(setNotificationsSelectedPage(selectedPage + 1));
    }
  }, [dispatch, selectedPage, totalPages]);

  const resetCallback = useCallback(() => {
    dispatch(setNotificationsSelectedPage(1));
  }, [dispatch]);

  const debounceSearchString = useDebounce(search, 1000);


  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    console.log("sub Category in Table view:",subCategories);
 //  dispatch(setNotificationDynamicFilters(subCategories));
  }, [subCategories, dispatch]);

  useEffect(() => {
    dispatch(initializeNotifications(selectedPage, updated,true));
  }, [dispatch, selectedPage, updated, debounceSearchString]);

  const handleSelectionChange  = useCallback(
    (value) => {
      onChangeSelection(value);
console.log("VAlouuuuuuuuuuuuuuuuuuuuuuuu",value);
      if (value.length !== 0) {
       dispatch(setBulkActionsNotifications(true));
        const selectedItems: (Notificatons | null)[] = value.map((index: number, key: any) => {
          if (index >= 0 && data && index < data.length) {
            return data[index].raw;
          } else {
            return null;
          }
        });
        console.log("selected Items:",selectedItems);
        dispatch(setSelectedNotifications(selectedItems))

      }
       else {
        dispatch(setBulkActionsNotifications(false));
      }
    },

    [dispatch, data,onChangeSelection]
);


  // const handleRowClick = (record: any) => {
  //   if (!record.is_read) {
  //     console.log("MessageID:" ,record.id);
  //     dispatch(markReadNotifications([record.id]));
  //     console.log("Unread======================");
  //   }
  //   else{
  //     console.log("Read======================");
  //   }
  // };

  return (
    <>
      <Table
        columns={columns}
        dataSource={data} 
        // onRow={(record) => {
        //   return {
        //     onClick: () => handleRowClick(record), // Handle row click
        //   };
        // }}
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: handleSelectionChange,
        }}
        scroll={{ y: "70vh" }}
        pagination={false}
      />
      <TablePagination
        page={page}
        onBack={onBack}
        onNext={onNext}
        resetCallback={resetCallback}
      />
    </>
  );
}
