import { Layout as AntLayout, Menu, Dropdown, Select, List } from "antd";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  faultyKioskIcon,
    navIcon,
    notificationIcon,
    unautherizedMovementIcon,
    zoneViolationIcon,
    zypLogo,
    cloudConnectivityIcon,
    geofenceReturnIcon,
} from "svgs";
import { ROUTES } from "utils/constants";
import {
    clearCommonState,
    markReadNotifications,
    setUsersEmpty,
    setVehicleStateEmpty,
    toggleLoading
} from "store/actions";
import { AppState } from "store";
// import zypImage from "images/zyp-logo.png"
import {useEffect, useMemo} from "react";
import {parseFcmNotificationTable} from "../../utils/parsers/fcm_notification_parser";
import {initializeNotification} from "../../store/actions/fcm_notification_actions";
// import {parseBatteryTable} from "../../utils/parsers";
import {USER_TYPE} from "../../utils/mapped-data";
const { Header: AntHeader } = AntLayout;
// const { Option, OptGroup } = Select;
interface ComponentProps {
  toggleCollapse: () => void;
}

export default function AppHeader(props: ComponentProps) {
  const { toggleCollapse } = props;
  const history = useHistory();

  const dispatch = useDispatch();
  const [cookies, setCookies, removeCookies] = useCookies();
  const { userType, phone } = useSelector<AppState, AppState["common"]>(
    (state) => state.common
  );
    const {
        notifications,
    } = useSelector<AppState, AppState["notifications"]>((state) => state.notifications);
  const firstName = (cookies?.meta?.user?.firstName as string) || "";
  const lastName = (cookies?.meta?.user?.lastName as string) || "";
    const data = useMemo(
        () => parseFcmNotificationTable(notifications),
        [notifications]
    );
    useEffect(() => {
        dispatch(initializeNotification());
    }, [dispatch]);

    const clearAllState = () => {
        dispatch(setUsersEmpty())
        dispatch(clearCommonState())
        dispatch(setVehicleStateEmpty())
    }
    const logoutMethod = () => {
        console.log("Method is called...");
        removeCookies("meta", {path: "/"});
        dispatch(toggleLoading(true));
        clearAllState();
        setTimeout(() => {
            history.push(ROUTES.login);
            dispatch(toggleLoading(false));
        }, 1000);
    }
    const onNotificationClick = (visible:boolean) => {
        if (visible) {
            let notificationIDs:string[] = [];
            data.forEach((notification) => {
                notificationIDs.push(notification.id)
            })
            if (notificationIDs.length > 0 ) {
                dispatch(markReadNotifications(notificationIDs))
            }

        } else if (!visible) {
            dispatch(initializeNotification())
        }
    }
    const getFormatedDate = (inputString:string) => {
        const inputDate = new Date(inputString);

        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
      };

       return inputDate.toLocaleString('en-US', options);
    }
    const notification = (
        <>
            <List
                itemLayout="horizontal"
                dataSource={data}
                style={{ width: "250px", background: "#fff", maxHeight: '380px', overflow: 'scroll', boxShadow: "rgb(0 0 0 / 30%) 1px 1px 16px -6px" }}
                renderItem={(item: any, index: any) => (
                    <>
                    <List.Item style={{display: "block"}}>
                        <List.Item.Meta
                            avatar= {
                              item.title == "Zone Violation"
                                  ? zoneViolationIcon
                                  : item.title == "Kiosk Faulty Slot"
                                  ? faultyKioskIcon
                                  : item.title == "Cloud Connectivity"
                                  ? cloudConnectivityIcon
                                  :item.title == "Geofence Return"
                                  ? geofenceReturnIcon:
                                  unautherizedMovementIcon
                          } 
                            title={ item.title }
                            description={ item.body }
                            style={{ paddingLeft: "20px" }}
                        />
                        <List.Item.Meta style={{textAlign: "center", paddingLeft: "25px"}}
                            title={ getFormatedDate(item.notification_time) }
                        />


                    </List.Item>
                    </>

                )}
            />
        </>
    );


    const menu = (
    <Menu className="user-menu">
      {/*<Menu.Item>*/}
      {/*  <Link to={"#"}>My Profile</Link>*/}
      {/*</Menu.Item>*/}
        <Menu.Item>
            <p>{firstName} {lastName}</p>
            {userType != undefined && <p>{USER_TYPE[userType]}</p>}
        </Menu.Item>
      <Menu.Item>

        <a
          onClick={logoutMethod}
          className="logout-menu"
        >
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <AntHeader id="app-header" className="app-header">
      <span className="nav-icon" onClick={toggleCollapse}>
        {navIcon}
      </span>
      <span className="--app-title-logo">{zypLogo}</span>

      <section className="dropdowns-container">
       {/* <Select defaultValue="All Locations" suffixIcon={customDropdownIcon}>*/}
       {/*   <Option value="All Locations">All Locations</Option>*/}
       {/*   <OptGroup label="UAE">*/}
       {/*     <Option value="Abu Dhabi">Abu Dhabi</Option>*/}
       {/*     <Option value="Al Ain">Al Ain</Option>*/}
       {/*     <Option value="Dubai">Dubai</Option>*/}
       {/*   </OptGroup>*/}
       {/*   <OptGroup label="KSA">*/}
       {/*     <Option value="Riyadh">Riyadh</Option>*/}
       {/*   </OptGroup>*/}
       {/* </Select>*/}
       {/*{userType == 2 || userType == 3 && <Select*/}
       {/*   defaultValue="All Organizations"*/}
       {/*   suffixIcon={customDropdownIcon}*/}
       {/*   dropdownRender={(menu) => (*/}
       {/*     <>*/}
       {/*       <div className="select-search-container">*/}
       {/*         <Input prefix={searchIcon} placeholder="Search..." />*/}
       {/*       </div>*/}
       {/*       {menu}*/}
       {/*     </>*/}
       {/*   )}*/}
       {/* >*/}
       {/*   <Option value="All Clients">All Organizations</Option>*/}
       {/*   <Option value="Talabat">Talabat</Option>*/}
       {/*   <Option value="Zomato">Zomato</Option>*/}
       {/*   <Option value="Deliveroo">Deliveroo</Option>*/}
       {/* </Select>}*/}

          <Dropdown
              overlay={notification}
              trigger={["click"]}
              overlayClassName="header-dropdown"
              onVisibleChange={onNotificationClick}
          >
              <span className="notification-icon" style={{ position: 'relative', cursor: 'pointer' }}>{notificationIcon}
                  { data.length > 0 && <span className="notification-badge">{data.length}</span> }
              </span>
          </Dropdown>

          <Dropdown
          overlay={menu}
          trigger={["click"]}
          overlayClassName="header-dropdown"
        >
          <span className="menu-btn">{firstName[0]}</span>
        </Dropdown>
      </section>
    </AntHeader>
  );
}
