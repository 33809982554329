import React from "react";
import styled from "styled-components";
import { Card } from "antd";
import { EyeOutlined } from "@ant-design/icons";


const statsData = [
  { value: "150", label: "Total Trips", color: "purple"  },
  { value: "3800 KM", label: "Distance Travelled", color: "brown" },
  { value: "130", label: "Total Riders", color: "blue" },
  { value: "55", label: "Active Riders", color: "green" },
  { value: "72", label: "Total Issues", color: "orange" },
  { value: "26", label: "Pending Issues", color: "red" },
 
];

const StatCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  text-align: center;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
`;

const StatContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Ensures 2 cards per row */
  gap: 15px;
  justify-content: center;
  align-items: center;
  margin: 0px;
  max-width: 400px; /* Restrict width for better alignment */
`;

const Value = styled.div`
  font-size: 22px !important;
  font-weight: bold;
`;

const Label = styled.div`
  font-size: 12px !important;
  color: #777;
`;

const ViewLink = styled.div`
  font-size: 12px;
  color: orange;
  cursor: pointer;
`;

export default function HorizontalTiles() {
  return (
    <StatContainer>
      {statsData.map((stat, index) => (
        <StatCard key={index}>
          <Value style={{ color: stat.color }}>{stat.value}</Value>
          <Label>{stat.label}</Label>
    
        </StatCard>
      ))}
    </StatContainer>
  );
}
