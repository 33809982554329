import { Formik } from "formik";
import {useCallback, useMemo, useState} from "react";
import { Form, Button, Typography, Input, Upload } from "antd";

import {FormItem, RadioGroup, SelectSearch} from "components";
import {ADD_ISSUE_FORMIK, BATTERY_FIRMWARE, ORGANIZATION_FIRMWARE} from "utils/formik-data";
import {BatteryValues, OrganizationValues} from "types/formik";
import { SelectType } from "types";
import {addBattery, addOrganization, setModalName, updateOrganization} from "store/actions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import organizations from "../../store/reducers/clients-reducer";

const { Title, Text } = Typography;

export default function EditOrganizationForm() {
    const{
        organizations: {selectedOrganization},
        common: { userType =1 },
    } = useSelector<AppState, AppState>((state) => state);
    const clinetType = [{label: "B2B", value: 1},{label: "B2C", value: 2}]
    const [business, setBusiness] = useState(1);
    const dispatch = useDispatch();
    const orgType = [
        {
            label: "Fleet Owner",
            value: "FLEET_OWNER"
        },
        {
            label: "Fleet Client",
            value: "FLEET_CLIENT"
        },
    ];
    var filerOrg = orgType.filter(or => {
        console.log("userType : ", userType,  userType != 1);
        if (userType != 1) {
            return or.value != "FLEET_OWNER"
        }
        return or

    })
    const initialValues = useMemo(() => {
        return {
            name: selectedOrganization?.name || "",
            street_address: selectedOrganization?.address?.streetAddress1 || "",
            city: selectedOrganization?.address?.city || "",
            state: selectedOrganization?.address?.state || "",
            country: selectedOrganization?.address?.country || "",
            org_type: selectedOrganization?.orgType || "",
            business_type: 1,
            national_id: "",
            email: "",
            phone_number: "",
        }
    }, [selectedOrganization]);


    const onCancels = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    const onSubmit = useCallback(
        (values: OrganizationValues) => {
            console.log("organization Values : ", values)
            dispatch(
                updateOrganization({
                    id: selectedOrganization?.id || "",
                    name: values.name || "",
                    street_address: values.street_address,
                    city: values.city || "",
                    state: values.state || "",
                    country: values.country || "",
                    org_type: values.org_type || "",
                })
            );
            onCancels();
        },
        [dispatch, onCancels, selectedOrganization?.id]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ORGANIZATION_FIRMWARE.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                  setFieldValue,
                  dirty,
                  isValid,
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
              }) => (
                <div className="form-container">
                    <Title level={5}>Update Client</Title>
                    <Text className="description" type="secondary">
                    Please provide the following details to update client details
                    </Text>
                    <Form className="--add-vehicles-form" onFinish={handleSubmit}>
                        <section className="">
                            <FormItem
                                verticalAlign="flex-start"
                                error={errors.business_type}
                                label="Type"
                                required
                            >
                                <RadioGroup
                                    options={clinetType}
                                    value={values.business_type || 1}
                                    onChange={(selected) => {
                                        setFieldValue("business_type", selected.target.value);setBusiness(selected.target.value);
                                    }}
                                />
                            </FormItem>
                            <FormItem label="Name" error={errors.name} required>
                                <Input
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    placeholder="Name"
                                />
                            </FormItem>
                            <FormItem label="Street Address" error={errors.street_address} required>
                                <Input
                                    name="street_address"
                                    value={values.street_address}
                                    onChange={handleChange}
                                    placeholder="Street Address"
                                />
                            </FormItem>
                            <FormItem label="City" error={errors.city} required>
                                <Input
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange}
                                    placeholder="City"
                                />
                            </FormItem>
                            <FormItem label="Province" error={errors.state} required>
                                <Input
                                    name="state"
                                    value={values.state}
                                    onChange={handleChange}
                                    placeholder="Province"
                                />
                            </FormItem>
                            <FormItem label="Country" error={errors.country} required>
                                <Input
                                    name="country"
                                    value={values.country}
                                    onChange={handleChange}
                                    placeholder="Country"
                                />
                            </FormItem>
                            <FormItem label="Organization Type" error={errors.org_type} required>
                                <SelectSearch
                                    isMultiple={false}
                                    value={values.org_type || ""}
                                    items={filerOrg}
                                    setFieldValue={setFieldValue}
                                    fieldName="org_type"
                                    placeholder="Select"
                                    onChangeProp={(value) => {
                                        setFieldValue("org_type", value);
                                    }}
                                />
                            </FormItem>
                        </section>
                        <div className="action-btn-container">
                            <>
                                <Button className="cancel-btn" onClick={onCancels}>
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={(!isValid && dirty)}
                                >
                                    Save
                                </Button>
                            </>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}