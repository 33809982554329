/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.2
// source: fcm_notification_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as fcm_notification_service_pb from './fcm_notification_service_pb';


export class FcmNotificationServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetUnreadNotification = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/GetUnreadNotification',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.GetUnreadNotificationRequest,
    fcm_notification_service_pb.GetUnreadNotificationResponse,
    (request: fcm_notification_service_pb.GetUnreadNotificationRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.GetUnreadNotificationResponse.deserializeBinary
  );

  getUnreadNotification(
    request: fcm_notification_service_pb.GetUnreadNotificationRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.GetUnreadNotificationResponse>;

  getUnreadNotification(
    request: fcm_notification_service_pb.GetUnreadNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetUnreadNotificationResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.GetUnreadNotificationResponse>;

  getUnreadNotification(
    request: fcm_notification_service_pb.GetUnreadNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetUnreadNotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/GetUnreadNotification',
        request,
        metadata || {},
        this.methodDescriptorGetUnreadNotification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/GetUnreadNotification',
    request,
    metadata || {},
    this.methodDescriptorGetUnreadNotification);
  }

  methodDescriptorMarkReadNotificationsByIds = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/MarkReadNotificationsByIds',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.MarkReadNotificationsByIdsRequest,
    fcm_notification_service_pb.MarkReadNotificationsByIdsResponse,
    (request: fcm_notification_service_pb.MarkReadNotificationsByIdsRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.MarkReadNotificationsByIdsResponse.deserializeBinary
  );

  markReadNotificationsByIds(
    request: fcm_notification_service_pb.MarkReadNotificationsByIdsRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.MarkReadNotificationsByIdsResponse>;

  markReadNotificationsByIds(
    request: fcm_notification_service_pb.MarkReadNotificationsByIdsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.MarkReadNotificationsByIdsResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.MarkReadNotificationsByIdsResponse>;

  markReadNotificationsByIds(
    request: fcm_notification_service_pb.MarkReadNotificationsByIdsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.MarkReadNotificationsByIdsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/MarkReadNotificationsByIds',
        request,
        metadata || {},
        this.methodDescriptorMarkReadNotificationsByIds,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/MarkReadNotificationsByIds',
    request,
    metadata || {},
    this.methodDescriptorMarkReadNotificationsByIds);
  }

  methodDescriptorGetNotificationForUser = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/GetNotificationForUser',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.GetNotificationForUserRequest,
    fcm_notification_service_pb.GetNotificationForUserResponse,
    (request: fcm_notification_service_pb.GetNotificationForUserRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.GetNotificationForUserResponse.deserializeBinary
  );

  getNotificationForUser(
    request: fcm_notification_service_pb.GetNotificationForUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.GetNotificationForUserResponse>;

  getNotificationForUser(
    request: fcm_notification_service_pb.GetNotificationForUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetNotificationForUserResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.GetNotificationForUserResponse>;

  getNotificationForUser(
    request: fcm_notification_service_pb.GetNotificationForUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetNotificationForUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/GetNotificationForUser',
        request,
        metadata || {},
        this.methodDescriptorGetNotificationForUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/GetNotificationForUser',
    request,
    metadata || {},
    this.methodDescriptorGetNotificationForUser);
  }

  methodDescriptorGetNotifications = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/GetNotifications',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.GetNotificationsRequest,
    fcm_notification_service_pb.GetNotificationsResponse,
    (request: fcm_notification_service_pb.GetNotificationsRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.GetNotificationsResponse.deserializeBinary
  );

  getNotifications(
    request: fcm_notification_service_pb.GetNotificationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.GetNotificationsResponse>;

  getNotifications(
    request: fcm_notification_service_pb.GetNotificationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetNotificationsResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.GetNotificationsResponse>;

  getNotifications(
    request: fcm_notification_service_pb.GetNotificationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetNotificationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/GetNotifications',
        request,
        metadata || {},
        this.methodDescriptorGetNotifications,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/GetNotifications',
    request,
    metadata || {},
    this.methodDescriptorGetNotifications);
  }

  methodDescriptorGetNotificationCategories = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/GetNotificationCategories',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.GetNotificationCategoriesRequest,
    fcm_notification_service_pb.GetNotificationCategoriesResponse,
    (request: fcm_notification_service_pb.GetNotificationCategoriesRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.GetNotificationCategoriesResponse.deserializeBinary
  );

  getNotificationCategories(
    request: fcm_notification_service_pb.GetNotificationCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.GetNotificationCategoriesResponse>;

  getNotificationCategories(
    request: fcm_notification_service_pb.GetNotificationCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetNotificationCategoriesResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.GetNotificationCategoriesResponse>;

  getNotificationCategories(
    request: fcm_notification_service_pb.GetNotificationCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetNotificationCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/GetNotificationCategories',
        request,
        metadata || {},
        this.methodDescriptorGetNotificationCategories,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/GetNotificationCategories',
    request,
    metadata || {},
    this.methodDescriptorGetNotificationCategories);
  }

  methodDescriptorGetNotificationSubCategories = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/GetNotificationSubCategories',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.GetNotificationSubCategoriesRequest,
    fcm_notification_service_pb.GetNotificationSubCategoriesResponse,
    (request: fcm_notification_service_pb.GetNotificationSubCategoriesRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.GetNotificationSubCategoriesResponse.deserializeBinary
  );

  getNotificationSubCategories(
    request: fcm_notification_service_pb.GetNotificationSubCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.GetNotificationSubCategoriesResponse>;

  getNotificationSubCategories(
    request: fcm_notification_service_pb.GetNotificationSubCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetNotificationSubCategoriesResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.GetNotificationSubCategoriesResponse>;

  getNotificationSubCategories(
    request: fcm_notification_service_pb.GetNotificationSubCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.GetNotificationSubCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/GetNotificationSubCategories',
        request,
        metadata || {},
        this.methodDescriptorGetNotificationSubCategories,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/GetNotificationSubCategories',
    request,
    metadata || {},
    this.methodDescriptorGetNotificationSubCategories);
  }

  methodDescriptorAddNotificationCategory = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/AddNotificationCategory',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.AddNotificationCategoryRequest,
    fcm_notification_service_pb.AddNotificationCategoryResponse,
    (request: fcm_notification_service_pb.AddNotificationCategoryRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.AddNotificationCategoryResponse.deserializeBinary
  );

  addNotificationCategory(
    request: fcm_notification_service_pb.AddNotificationCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.AddNotificationCategoryResponse>;

  addNotificationCategory(
    request: fcm_notification_service_pb.AddNotificationCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.AddNotificationCategoryResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.AddNotificationCategoryResponse>;

  addNotificationCategory(
    request: fcm_notification_service_pb.AddNotificationCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.AddNotificationCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/AddNotificationCategory',
        request,
        metadata || {},
        this.methodDescriptorAddNotificationCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/AddNotificationCategory',
    request,
    metadata || {},
    this.methodDescriptorAddNotificationCategory);
  }

  methodDescriptorAddNotificationSubCategory = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/AddNotificationSubCategory',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.AddNotificationSubCategoryRequest,
    fcm_notification_service_pb.AddNotificationSubCategoryResponse,
    (request: fcm_notification_service_pb.AddNotificationSubCategoryRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.AddNotificationSubCategoryResponse.deserializeBinary
  );

  addNotificationSubCategory(
    request: fcm_notification_service_pb.AddNotificationSubCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.AddNotificationSubCategoryResponse>;

  addNotificationSubCategory(
    request: fcm_notification_service_pb.AddNotificationSubCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.AddNotificationSubCategoryResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.AddNotificationSubCategoryResponse>;

  addNotificationSubCategory(
    request: fcm_notification_service_pb.AddNotificationSubCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.AddNotificationSubCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/AddNotificationSubCategory',
        request,
        metadata || {},
        this.methodDescriptorAddNotificationSubCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/AddNotificationSubCategory',
    request,
    metadata || {},
    this.methodDescriptorAddNotificationSubCategory);
  }

  methodDescriptorUpdateNotificationCategory = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/UpdateNotificationCategory',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.UpdateNotificationCategoryRequest,
    fcm_notification_service_pb.UpdateNotificationCategoryResponse,
    (request: fcm_notification_service_pb.UpdateNotificationCategoryRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.UpdateNotificationCategoryResponse.deserializeBinary
  );

  updateNotificationCategory(
    request: fcm_notification_service_pb.UpdateNotificationCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.UpdateNotificationCategoryResponse>;

  updateNotificationCategory(
    request: fcm_notification_service_pb.UpdateNotificationCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.UpdateNotificationCategoryResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.UpdateNotificationCategoryResponse>;

  updateNotificationCategory(
    request: fcm_notification_service_pb.UpdateNotificationCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.UpdateNotificationCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/UpdateNotificationCategory',
        request,
        metadata || {},
        this.methodDescriptorUpdateNotificationCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/UpdateNotificationCategory',
    request,
    metadata || {},
    this.methodDescriptorUpdateNotificationCategory);
  }

  methodDescriptorUpdateNotificationSubCategory = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/UpdateNotificationSubCategory',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.UpdateNotificationSubCategoryRequest,
    fcm_notification_service_pb.UpdateNotificationSubCategoryResponse,
    (request: fcm_notification_service_pb.UpdateNotificationSubCategoryRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.UpdateNotificationSubCategoryResponse.deserializeBinary
  );

  updateNotificationSubCategory(
    request: fcm_notification_service_pb.UpdateNotificationSubCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.UpdateNotificationSubCategoryResponse>;

  updateNotificationSubCategory(
    request: fcm_notification_service_pb.UpdateNotificationSubCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.UpdateNotificationSubCategoryResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.UpdateNotificationSubCategoryResponse>;

  updateNotificationSubCategory(
    request: fcm_notification_service_pb.UpdateNotificationSubCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.UpdateNotificationSubCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/UpdateNotificationSubCategory',
        request,
        metadata || {},
        this.methodDescriptorUpdateNotificationSubCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/UpdateNotificationSubCategory',
    request,
    metadata || {},
    this.methodDescriptorUpdateNotificationSubCategory);
  }

  methodDescriptorDeleteNotificationCategory = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/DeleteNotificationCategory',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.DeleteNotificationCategoryRequest,
    fcm_notification_service_pb.DeleteNotificationCategoryResponse,
    (request: fcm_notification_service_pb.DeleteNotificationCategoryRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.DeleteNotificationCategoryResponse.deserializeBinary
  );

  deleteNotificationCategory(
    request: fcm_notification_service_pb.DeleteNotificationCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.DeleteNotificationCategoryResponse>;

  deleteNotificationCategory(
    request: fcm_notification_service_pb.DeleteNotificationCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.DeleteNotificationCategoryResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.DeleteNotificationCategoryResponse>;

  deleteNotificationCategory(
    request: fcm_notification_service_pb.DeleteNotificationCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.DeleteNotificationCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/DeleteNotificationCategory',
        request,
        metadata || {},
        this.methodDescriptorDeleteNotificationCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/DeleteNotificationCategory',
    request,
    metadata || {},
    this.methodDescriptorDeleteNotificationCategory);
  }

  methodDescriptorDeleteNotificationSubCategory = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/DeleteNotificationSubCategory',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.DeleteNotificationSubCategoryRequest,
    fcm_notification_service_pb.DeleteNotificationSubCategoryResponse,
    (request: fcm_notification_service_pb.DeleteNotificationSubCategoryRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.DeleteNotificationSubCategoryResponse.deserializeBinary
  );

  deleteNotificationSubCategory(
    request: fcm_notification_service_pb.DeleteNotificationSubCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.DeleteNotificationSubCategoryResponse>;

  deleteNotificationSubCategory(
    request: fcm_notification_service_pb.DeleteNotificationSubCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.DeleteNotificationSubCategoryResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.DeleteNotificationSubCategoryResponse>;

  deleteNotificationSubCategory(
    request: fcm_notification_service_pb.DeleteNotificationSubCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.DeleteNotificationSubCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/DeleteNotificationSubCategory',
        request,
        metadata || {},
        this.methodDescriptorDeleteNotificationSubCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/DeleteNotificationSubCategory',
    request,
    metadata || {},
    this.methodDescriptorDeleteNotificationSubCategory);
  }

  methodDescriptorSendNotificationToClient = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/SendNotificationToClient',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.SendNotificationToClientRequest,
    fcm_notification_service_pb.SendNotificationToClientResponse,
    (request: fcm_notification_service_pb.SendNotificationToClientRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.SendNotificationToClientResponse.deserializeBinary
  );

  sendNotificationToClient(
    request: fcm_notification_service_pb.SendNotificationToClientRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.SendNotificationToClientResponse>;

  sendNotificationToClient(
    request: fcm_notification_service_pb.SendNotificationToClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.SendNotificationToClientResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.SendNotificationToClientResponse>;

  sendNotificationToClient(
    request: fcm_notification_service_pb.SendNotificationToClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.SendNotificationToClientResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/SendNotificationToClient',
        request,
        metadata || {},
        this.methodDescriptorSendNotificationToClient,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/SendNotificationToClient',
    request,
    metadata || {},
    this.methodDescriptorSendNotificationToClient);
  }

  methodDescriptorSaveNotification = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/SaveNotification',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.SaveNotificationRequest,
    fcm_notification_service_pb.SaveNotificationResponse,
    (request: fcm_notification_service_pb.SaveNotificationRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.SaveNotificationResponse.deserializeBinary
  );

  saveNotification(
    request: fcm_notification_service_pb.SaveNotificationRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.SaveNotificationResponse>;

  saveNotification(
    request: fcm_notification_service_pb.SaveNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.SaveNotificationResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.SaveNotificationResponse>;

  saveNotification(
    request: fcm_notification_service_pb.SaveNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.SaveNotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/SaveNotification',
        request,
        metadata || {},
        this.methodDescriptorSaveNotification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/SaveNotification',
    request,
    metadata || {},
    this.methodDescriptorSaveNotification);
  }

  methodDescriptorAddNotification = new grpcWeb.MethodDescriptor(
    '/api.FcmNotificationService/AddNotification',
    grpcWeb.MethodType.UNARY,
    fcm_notification_service_pb.AddNotificationRequest,
    fcm_notification_service_pb.AddNotificationResponse,
    (request: fcm_notification_service_pb.AddNotificationRequest) => {
      return request.serializeBinary();
    },
    fcm_notification_service_pb.AddNotificationResponse.deserializeBinary
  );

  addNotification(
    request: fcm_notification_service_pb.AddNotificationRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_notification_service_pb.AddNotificationResponse>;

  addNotification(
    request: fcm_notification_service_pb.AddNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.AddNotificationResponse) => void): grpcWeb.ClientReadableStream<fcm_notification_service_pb.AddNotificationResponse>;

  addNotification(
    request: fcm_notification_service_pb.AddNotificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_notification_service_pb.AddNotificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmNotificationService/AddNotification',
        request,
        metadata || {},
        this.methodDescriptorAddNotification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmNotificationService/AddNotification',
    request,
    metadata || {},
    this.methodDescriptorAddNotification);
  }

}

