import { Select ,Tabs } from "antd";
import React, { useState, useEffect, useMemo } from 'react';
import { SwitchButton } from "components/base";
import { useCallback } from "react";
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { setSelectedDashboard, setSelectedTab ,initializeClients} from "store/actions";
import { customDropdownIcon, filledDropdownIcon } from "svgs";
import { CustomerBillingItem, DashboardState } from "types";
import { DASHBOARD_OPTIONS, ROUTES } from "utils/constants";
import { DatePicker, Form } from 'antd';
import FinancialWidget from 'components/charts/financial-widget'; 
import {initializeCustomerBilling, setCustomerBill, setTotalBill} from "../../store/actions/billing-actions";
import FinancialsTileContainer from 'components/tab-content/dashboard-content/tile-containers/financials-container'; 
import TabNavs from "components/tab-navs";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { parseOrganizationClients} from "../../utils/parsers";
import { da } from "date-fns/locale";

const { Option } = Select;
const { RangePicker } = DatePicker; 
const { TabPane } = Tabs;

const ALL_TABS = [
  { key: 'vehicles', tab: 'Vehicles' },
  { key: 'financials', tab: 'Financials' },
];


export default function DashboardHeader() {
  const dispatch = useDispatch();
  const [{ meta }] = useCookies();
  const history = useHistory();
const baseRoute =  ROUTES.dashboard ;


const {
    organizations,
    page,
    search,
    filters: { listType },
  } = useSelector<AppState, AppState["organizations"]>((state) => state.organizations);
  const { selectedPage, totalPages } = page;
  const data = useMemo(
      () => parseOrganizationClients(organizations),
      [organizations, listType, search]
  );

   console.log("data of organization page : ", data)
  
   const {
    common: { userType =1 },
  } = useSelector<AppState, AppState>((state) => state);
  
  const DASHBOARD_TABS = userType === 2 ? [ { key: 'financials', tab: 'Financials' }] : ALL_TABS;

  const { selectedDashboard } = useSelector<AppState, AppState["dashboard"]>(
    (state) => state.dashboard
  );
  const [selectedDate, setSelectedDate] = useState<string | null>(dayjs().format('YYYY-MM-DD'));
  const [selectedOrganization, setSelectedOrganization] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(
    userType === 2 ? "financials" : DASHBOARD_TABS[0].key
  );

   useEffect(() => {
    dispatch(initializeClients());
       // Set the selected dashboard based on user type
       if (userType === 2) {
        setSelectedDashboard("financials");
        setActiveTab("financials");
      } else {
        setSelectedDashboard("vehicles");
        setActiveTab("vehicles");
      }
  }, [dispatch, userType]);







  // Update selectedOrganization once data is available
  useEffect(() => {
    if (data && data.length > 0 && selectedOrganization === null) {
      const orgId = data[0].raw.id;
      setSelectedOrganization(orgId);
    }
  }, [data, selectedOrganization]);

  
   const [selectedFilter, setSelectedFilter] = useState("this month"); // Default filter value
   // Select the billing state from Redux
   const { totalBill, bill } = useSelector((state: AppState) => state.billing);
   // Set the default active tab based on userType
 

  const handleOrganizationChange = (value: string) => {
    setSelectedOrganization(value);
    if (value && selectedDate) {
      const month = dayjs(selectedDate).month() + 1; // `.month()` returns 0-indexed, so we add 1
      const year = dayjs(selectedDate).year();
    dispatch(setCustomerBill({
      billNo: 0,
      customerNo: 0,
      customerName: "",
      customerId: "",
      subscriptionNo: 0,
      issueDate: "",
      postingDate: "",
      dueDate: "",
      status: "",
      lateFeeApplied: 0,
      pb_package: "",
      plan: "",
      batch: "",
      term: "",
      perSwapCost: "",
      totalOperationalBikes: 0,
      billingMonth: month,
      billingYear: year,
      vehicleLicencePlate: "",
      billingItems:'{"lineItems":[{"name":"Swaps","quantity":0,"rate":0,"total":0},{"name":"Battery Sub Charges","quantity":0,"rate":0,"total":0}],"dynamic_items":[]}', 
    }));
     dispatch(setTotalBill("PKR 0"));
  //  dispatch(initializeCustomerBilling({organization_id: value, date: selectedDate}));
    }
  };
   const onTabChange = (key: string) => {
    setActiveTab(key);
    console.log('Key:', key);
    onChange(key as DashboardState["selectedDashboard"]);
  };
  const onChange = useCallback(
    (value: DashboardState["selectedDashboard"]) => {
      dispatch(setSelectedDashboard(value));
    },
    [dispatch]
  );

  const handleFilterChange = (value: string) => {
    setSelectedFilter(value);
    // Add logic here to filter data based on the selected value
    console.log("Selected filter:", value);
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    console.log("Selected Date Range:", dateStrings);
    // Add logic here to filter data based on the selected date range
  };

    const disabledDate = (current: any) => {
      return current && current > dayjs().endOf("day"); // Disable future dates
    };

  const handleDateChange = (date: moment.Moment | null, dateString: string) => {
    if (date) {
    const formattedDate = date.format('YYYY-MM-DD'); 
    const month = dayjs(formattedDate).month() + 1; // `.month()` returns 0-indexed, so we add 1
    const year = dayjs(formattedDate).year(); // `.year()` returns the year as a number    
    setSelectedDate(formattedDate);
    dispatch(setCustomerBill({
      billNo: 0,
      customerNo: 0,
      customerName: "",
      customerId: "",
      subscriptionNo: 0,
      issueDate: "",
      postingDate: "",
      dueDate: "",
      status: "",
      lateFeeApplied: 0,
      pb_package: "",
      plan: "",
      batch: "",
      term: "",
      perSwapCost: "",
      totalOperationalBikes: 0,
      billingMonth: month,
      billingYear: year,
      vehicleLicencePlate: "",
      billingItems:'{"lineItems":[{"name":"Swaps","quantity":0,"rate":0,"total":0},{"name":"Battery Sub Charges","quantity":0,"rate":0,"total":0}],"dynamic_items":[]}', 
    }));
     dispatch(setTotalBill("PKR 0"));
    console.log('Selected Date:', formattedDate); 
    } else {
      setSelectedDate(null);
    }
  };
  useEffect(() => {
    if (userType === 2) {
      dispatch(setSelectedDashboard("financials"));
      console.log('Selected Date in use user 2:', selectedDate);
      console.log('Selected Organization in use user 2:', selectedOrganization);
      if (selectedOrganization && selectedDate) {
        dispatch(initializeCustomerBilling({ 
          organization_id: selectedOrganization, 
          date: selectedDate 
        }));
        console.log('Selected Date in use user 2 After:', selectedDate);
        console.log('Selected Organization in use user 2 After:', selectedOrganization);
    }
  }
    else{ 
      if(selectedDashboard === "financials"  && selectedDate){
      if (meta?.user?.organizationId && selectedDate) {
      dispatch(initializeCustomerBilling({ 
        organization_id: meta.user.organizationId, 
        date: selectedDate 
      }));
      console.log('Selected Date in use Eff F:', selectedDate);
      console.log('Selected Organization in use Eff F:', selectedOrganization);
    }
    }}
   
   
  }, [dispatch, meta, selectedDate, selectedDashboard, userType,selectedOrganization]);

  const showOrganizationDropdown = selectedDashboard !== "vehicles" && userType === 2;

  const defaultOrganization = data?.length > 0 ? data[0].raw.id : undefined;

  return (
    <div className="common-header">
      {/* <Select
        value={selectedDashboard}
        bordered={false}
        dropdownClassName="select-dashboard-view"
        className="selected-label"
        suffixIcon={filledDropdownIcon}
        onChange={onChange}>
        {DASHBOARD_OPTIONS.map((option) => (
          <Option value={option.value}>{option.label}</Option>
        ))}
      </Select> */}
       <div style={{ marginLeft: '20px' }}>
       <Tabs activeKey={activeTab} onChange={onTabChange}>
          {DASHBOARD_TABS.map((tab) => (
            <TabPane tab={tab.tab} key={tab.key}>
              {/* Show content only for the active tab */}
              {activeTab === "vehicles" && userType !== 2 && (
                <div>
                  {/* Vehicles tab content */}
                </div>
              )}
              {activeTab === "financials" && (
                <div>
                  {/* Financials tab content */}
                </div>
              )}
            </TabPane>
          ))}
        </Tabs>
      </div>
     {/* <h1>Overview</h1> */}
      <div className="actions-container dashboard-header">
      {showOrganizationDropdown && (
       <section>
       <Form.Item label="Select Organization:" name="organization" className="form-item">
         <Select 
           defaultValue= "TCS" 
           onChange={handleOrganizationChange} 
           style={{ width: 160 }}
         >
           {data?.map((organization) => (
             <Option key={organization.raw.id} value={organization.raw.id}>
               {organization.name}
             </Option>
           ))}
         </Select>
       </Form.Item>
     </section>
        )}
   { selectedDashboard !== "vehicles"? <section>
      <Form.Item
        label="Month and Year:"
        name="datePicker"
        className="form-item">
        <DatePicker 
          picker="month"
          format="MMMM YYYY" 
          defaultValue={moment()}
          onChange={handleDateChange}
           />
      </Form.Item>  
    </section> :
    <section>

 {selectedFilter === 'custom' ?(
    <Form.Item
    label="Date Range:"
    name="dateRangePicker"
    className="form-item"
  >
      <RangePicker
              onChange={handleDateRangeChange}
              style={{ width: 250 }} // Adjust width as needed
              className="custom-range-picker" 
              picker="date"
              format="DD/MM/YYYY"
              ranges={{
                "Last 7 Days": [moment().subtract(7, "days"), moment()],
                "Last 15 Days": [moment().subtract(15, "days"), moment()],
                "This Month": [moment().startOf("month"), moment()],
              }}
              defaultPickerValue={[moment().subtract(30, "days"), moment().subtract(30, "days")]}
              disabledDate={disabledDate} // Prevent future dates
              
            />
            </Form.Item>
            ):(
              <Form.Item
              label="Filter:"
              name="filter"
              className="form-item"
            >
        <Select
        defaultValue="this month"
          id="filter"
          value={selectedFilter}
          onChange={handleFilterChange}
          style={{ width: 150 }} // Adjust width as needed
        >
          <Option value="this month">This Month</Option>
          <Option value="this week">This Week</Option>
          <Option value="today">Today</Option>
          <Option value="custom">Custom</Option>
        </Select>
        </Form.Item>
            )}
      </section>

      
}

    <div></div>
    {/* <section style={{ marginRight: '40px' }}>
      <Form.Item
        label="To:"
        name="datePicker"
        className="form-item">
        <DatePicker />
      </Form.Item>
    </section> */}
        {/* <section>
          <span className="dashboard-header-label">Partner:</span>
          <Select
            defaultValue="All"
            suffixIcon={customDropdownIcon}
            className="dashboard-dropdown">
            <Option value="All">All</Option>
          </Select>
        </section> */}
        {/* <section>
          <span className="dashboard-header-label">Vehicle Type:</span>
          <Select
            defaultValue="All"
            suffixIcon={customDropdownIcon}
            className="dashboard-dropdown">
            <Option value="All">All</Option>
          </Select>
        </section> */}
        {/* <section>
          <span className="dashboard-header-label">Filter By:</span>
          <Select
            defaultValue="Last Week"
            suffixIcon={customDropdownIcon}
            className="dashboard-dropdown">
            <Option value="last week">Last Week</Option>
            <Option value="last month">Last Month</Option>
            <Option value="custom">Custom</Option>
          </Select>
        </section> */}
      
      </div>
    </div>
  );
}
