import {
  InnerLayout,
  ClientsHeader,
  ClientsContent,
  ClientsFilter,
  TabNavs,
} from "components";
import { useState } from "react";
//import Wrapper from "zyp-kiosk";

export default function Clients() {
  const [selectedView, setSelectedView] = useState("B2B clients");

  return (
    <InnerLayout>
      {{
        header: <ClientsHeader />,
        tabs: (
          <TabNavs
            selected={selectedView}
            onChange={(value) => setSelectedView(value)}
            options={[{ key: "B2B clients" }, { key: "B2C clients" }]}

          />
        ),
        content: <ClientsContent selectedView={selectedView} />,
       // filter: selectedView !== "enquiries" ? <ClientsFilter /> : null,
      }}
    </InnerLayout>
    //   <Wrapper></Wrapper>
  );
}
