import {useDispatch} from "react-redux";
import {addUserFcmToken, initializeFirebaseConfiguration} from "./fcm_notification_actions";

const useFcmTokenCustomHook = () => {
    const dispatch = useDispatch();

    const performAction = () => {

    };

    return {
        performAction,
    };
};

export default useFcmTokenCustomHook