import { Formik } from "formik";
import {useCallback, useMemo, useState} from "react";
import { Form, Button, Typography, Input, Upload } from "antd";

import {FormItem, RadioGroup, SelectSearch} from "components";
import {ADD_ISSUE_FORMIK, BATTERY_FIRMWARE, ORGANIZATION_FIRMWARE} from "utils/formik-data";
import {BatteryValues, OrganizationValues} from "types/formik";
import { SelectType } from "types";
import {addBattery, addOrganization, setModalName} from "store/actions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";

const { Title, Text } = Typography;

export default function AddOrganizationForm() {
    const dispatch = useDispatch();
    const initialValues = useMemo(() => {
        return ORGANIZATION_FIRMWARE.initialValues
    }, []);
    const {
        common: { userType =1 },
    } = useSelector<AppState, AppState>((state) => state);
    const clinetType = [{label: "B2B", value: 1},{label: "B2C", value: 2}]
    const [business, setBusiness] = useState(1);
    const orgType = [
        {
            label: "Fleet Owner",
            value: "FLEET_OWNER"
        },
        {
            label: "Fleet Client",
            value: "FLEET_CLIENT"
        },
    ];

    var filerOrg = orgType.filter(or => {
        console.log("userType : ", userType,  userType != 1);
        if (userType != 1) {
            return or.value != "FLEET_OWNER"
        }
        return or

    })


    const onCancels = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    const onSubmit = useCallback(
        (values: OrganizationValues) => {
            console.log("organization Values :  ", values)
            dispatch(
                addOrganization({
                    name: values.name || "",
                    street_address: values.street_address,
                    city: values.city || "",
                    state: values.state || "",
                    country: values.country || "",
                    org_type: values.org_type || "",
                    business_type: values.business_type || 1,
                    email: values.email || "",
                    phone_number: values.phone_number || "",
                    national_id: values.national_id || "",
                })
            );
            onCancels();
        },
        [dispatch, onCancels]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ORGANIZATION_FIRMWARE.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                  setFieldValue,
                  dirty,
                  isValid,
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
              }) => (
                <div className="form-container">
                    <Title level={5}>Add Client</Title>
                    <Text className="description" type="secondary">
                    Please provide the following details to add new client
                    </Text>
                    <br />
                    <Form className="--add-vehicles-form" onFinish={handleSubmit}>
                        <section className="">
                            <FormItem
                                verticalAlign="flex-start"
                                error={errors.business_type}
                                label="Type"
                                required
                            >
                                <RadioGroup
                                    options={clinetType}
                                    value={values.business_type || 1}
                                    onChange={(selected) => {
                                        console.log("current value : ", values.business_type)
                                        setFieldValue("business_type", selected.target.value);setBusiness(selected.target.value);
                                    }}
                                />
                            </FormItem>
                            <FormItem label="Name" error={errors.name} required>
                                <Input
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    placeholder="Name"
                                />
                            </FormItem>
                            <FormItem label="Street Address" error={errors.street_address} required>
                                <Input
                                    name="street_address"
                                    value={values.street_address}
                                    onChange={handleChange}
                                    placeholder="Street Address"
                                />
                            </FormItem>
                            <FormItem label="City" error={errors.city} required>
                                <Input
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange}
                                    placeholder="City"
                                />
                            </FormItem>
                            <FormItem label="Province" error={errors.state} required>
                                <Input
                                    name="state"
                                    value={values.state}
                                    onChange={handleChange}
                                    placeholder="Province"
                                />
                            </FormItem>
                            <FormItem label="Country" error={errors.country} required>
                                <Input
                                    name="country"
                                    value={values.country}
                                    onChange={handleChange}
                                    placeholder="Country"
                                />
                            </FormItem>
                            {business == 1 &&
                            <FormItem label="Organization Type" error={errors.org_type} required>
                                <SelectSearch
                                    isMultiple={false}
                                    value={values.org_type || ""}
                                    items={filerOrg}
                                    setFieldValue={setFieldValue}
                                    fieldName="org_type"
                                    placeholder="Select"
                                    onChangeProp={(value) => {
                                        setFieldValue("org_type", value);
                                    }}
                                />
                            </FormItem>
                            }
                            {business == 2 &&
                                <>
                                    <FormItem label="Email" error={errors.email} required>
                                        <Input
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            placeholder="Email"
                                        />
                                    </FormItem>
                                    <FormItem label="National ID" error={errors.national_id} required>
                                        <Input
                                            name="national_id"
                                            value={values.national_id}
                                            onChange={handleChange}
                                            placeholder="National ID"
                                        />
                                    </FormItem>
                                    <FormItem label="Phone Number" error={errors.phone_number} required>
                                        <Input
                                            name="phone_number"
                                            value={values.phone_number}
                                            onChange={handleChange}
                                            placeholder="Phone Number"
                                        />
                                    </FormItem>
                                </>
                            }
                        </section>
                        <div className="action-btn-container">
                            <>
                                <Button className="cancel-btn" onClick={onCancels}>
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={(!isValid && dirty)}
                                >
                                    Save
                                </Button>
                            </>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}