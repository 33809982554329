import PhoneInput from "react-phone-input-2";
import { Form, Button, Typography, Row, Col, Tooltip } from "antd";

import { Tag } from "components/base";

import { downloadFileIcon, editIcon, uploadedFileIcon } from "svgs";
import { USER_TYPE } from "utils/mapped-data";
import { useMemo } from "react";
import { StateUserValues } from ".";

const { Title, Text } = Typography;

interface ComponentProps {
  setShowUserSummary: VoidFunction;
  addUserCallback: VoidFunction;
  values: StateUserValues;
}

export function UserSummary(props: ComponentProps) {
  const { setShowUserSummary, addUserCallback, values } = props;
  const href = useMemo(() => {
    return window.URL.createObjectURL(
      new Blob([values?.document_file_raw || new File([], "")], {
        type: values?.document_file_raw?.type,
      })
    );
  }, [values?.document_file_raw]);

  return (
    <div className="form-container">
      <Title level={5}>User Summary</Title>
      <Text className="description" type="secondary">
        <>
          <span className="edit-role edit" onClick={setShowUserSummary}>
            {editIcon} Edit User Info
          </span>
        </>
      </Text>
      <Form className="--add-users-form" onFinish={addUserCallback}>
        <Row className="user-summary" gutter={15}>
          <Col span={10} className="summary-labels">
            <p className="summary-label">Phone Number</p>
            <p className="summary-label">Email Address</p>
            <p className="summary-label">Full Name</p>
            <p className="summary-label">CNIC</p>
            <p className="summary-label">Role</p>

            {values.role === "3" && (
                <>
                <p className="summary-label">Vehicle ID</p>
                <p className="summary-label">Driver’s License Number</p>
                <p className="summary-label">Documents Uploaded</p>
                </>
            )}

          </Col>
          <Col span={14} className="summary-values">
            <p className="summary-value --phone">
              <PhoneInput
                country="ae"
                disabled
                inputClass="login-phone-input --user"
                buttonClass="user-flag --summary"
                value={values.phone_no}
                defaultMask={"..............."}
              />
            </p>
            <p title={values.email} className="summary-value">
              {values.email}
            </p>
            <p title={values.full_name} className="summary-value">
              {values.full_name}
            </p>
            <p title={values.national_id} className="summary-value">
              {values.national_id}
            </p>
            <p className="summary-value">
              {USER_TYPE[Number(values.role) as keyof typeof USER_TYPE]}
            </p>

            <p className="summary-value">
              {values.no_plate.map((licence) => (
                <Tag customClasses="user-tag vehicle-id-tag">{licence}</Tag>
              ))}
            </p>
            <p className="summary-value">
              {values.document_id ? values.document_id : <br />}
            </p>
            <p className="summary-value">
              {values.licence_no ? values.licence_no : <br />}
            </p>
            <p className="summary-value">
              {values.document_file && (
                <Tooltip
                  title={values?.document_file_raw?.name}
                  className="uploadded-file"
                >
                  <span className="file-icon">{uploadedFileIcon}</span>
                  <section className="file-info">
                    <span className="--name">
                      {values?.document_file_raw?.name}
                    </span>
                    <span className="--size">
                      {values?.document_file_raw?.size} KB
                    </span>
                  </section>
                  <a
                    href={href}
                    download={values?.document_file_raw?.name}
                    className="download-file-icon"
                  >
                    {downloadFileIcon}
                  </a>
                </Tooltip>
              )}
            </p>
          </Col>
        </Row>
        <div className="action-btn-container">
          <Button className="cancel-btn" htmlType="submit">
            OK
          </Button>
        </div>
      </Form>
    </div>
  );
}
