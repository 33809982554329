import Wrapper from "zyp-kiosk";
import {useSelector, useDispatch} from "react-redux";
import {AppState} from "../store";
import {USER_TYPE} from "../utils/mapped-data";
import { useEffect, useState } from "react";


export default function Kiosks() {
  const dispatch = useDispatch();
  const {
    common: { userType , token},
  } = useSelector<AppState, AppState>((state) => state);

  const [loading, setLoading] = useState(true); // Loading state
  const [currentToken, setCurrentToken] = useState<string | null>(null);

  useEffect(() => {
    // If token is available in Redux state
    if (token) {
        setCurrentToken(token);
        setLoading(false);
    } else {
        setLoading(true); 
    }
}, [token]);

if (loading) {
  return <div>Loading...</div>; // Show loading UI
}

    console.log("token: ", currentToken);
    console.log("reached in kiosk prod tab....");
    return (
<Wrapper
  login= {false} 
  token={currentToken} env="stg"  role={
    userType !== undefined 
      ? (USER_TYPE[userType] === "Fleet Owner Admin" ? "CLIENT_ADMIN" : USER_TYPE[userType]?.toUpperCase()) 
      : ''
  }
/>
    );
}